import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import SubMenuMobile from './SubMenuMobile';
import ItemMenuMobile from './ItemMenuMobile';
import ItemDisableMenuMobile from './ItemDisableMenuMobile';

const useStyles = makeStyles((theme) => ({
  accordion: {
    '& .MuiAccordionSummary-root': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.tertiary.main,
    },
    '& .MuiAccordionDetails-root': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'stretch',
    },
    '& .MuiAccordionDetails-root .MuiAccordionSummary-root': {
      backgroundColor: theme.palette.tertiary.main,
      '& .MuiAccordionSummary-content a': {
        color: theme.palette.primary.main,
        fontFamily: 'Trade Gothic LT STD',
        textTransform: 'none',
      },
    },
  },
}));

const MenuMobile = ({ openClose = () => {}, state, menuMobileData }) => {
  const { t, i18n } = useTranslation('menu');
  const menu = menuMobileData({ t, i18n });
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      {menu &&
        menu.map((item, id) => {
          return (
            <Box key={id}>
              {item.subMenu ? (
                <ItemMenuMobile
                  id={id}
                  exact={item.exact}
                  to={item.to}
                  labelLink={item.labelLink}
                  subMenu={item.subMenu}
                  openClose={openClose}
                  state={state}
                  expanded={expanded}
                  handleChange={handleChange}
                  classes={classes}
                  SubMenuMobile={SubMenuMobile}
                  target={item.target}
                />
              ) : (
                <ItemDisableMenuMobile
                  id={id}
                  exact={item.exact}
                  to={item.to}
                  labelLink={item.labelLink}
                  openClose={openClose}
                  state={state}
                  expanded={expanded}
                  handleChange={handleChange}
                  classes={classes}
                  target={item.target}
                />
              )}
            </Box>
          );
        })}
    </Fragment>
  );
};

MenuMobile.propTypes = {
  openClose: PropTypes.func,
  state: PropTypes.bool,
};

export default MenuMobile;
