import React from 'react';
import AccordionSummary from './AccordionSummary';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const ItemDisableMenuMobile = ({
  exact,
  id,
  to,
  labelLink,
  openClose = () => {},
  state,
  expanded,
  handleChange = () => {},
  classes,
  color,
  target = '_self',
}) => {
  return (
    <AccordionSummary
      color={color}
      aria-controls={`panel${id}a-content`}
      id={`panel${id}a-header`}
    >
      <NavLink
        className={classes.head}
        exact={exact}
        target={target}
        to={to}
        onClick={() => {
          openClose(!state);
        }}
      >
        {labelLink}
      </NavLink>
    </AccordionSummary>
  );
};

ItemDisableMenuMobile.propTypes = {
  labelLink: PropTypes.string,
  linkTo: PropTypes.string,
  openClose: PropTypes.func,
  handleChange: PropTypes.func,
  state: PropTypes.bool,
  exact: PropTypes.bool,
  id: PropTypes.number,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  classes: PropTypes.object,
  color: PropTypes.string,
};

export default ItemDisableMenuMobile;
