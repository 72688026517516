import React from 'react';

import { makeStyles } from '@material-ui/core';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  table: {
    '& th': {
      textTransform: 'uppercase',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.tertiary.main,
      fontSize: '14pt',
      border: '1px solid ' + theme.palette.primary.main,
    },
    '& td': {
      fontFamily: 'Trade Gothic LT STD',
      color: theme.palette.primary.main,
      fontSize: '12pt',
      border: '1px solid ' + theme.palette.primary.main,
      paddingTop: '4px',
      paddingBottom: '4px',
      '& a': {
        color: theme.palette.primary.main,
      },
      '& a:visited': {
        color: theme.palette.primary.main,
      },
    },
    '& svg': {
      paddingTop: '8px',
    },
  },
}));

const Table = ({ title, rows, columns, actions, className }) => {
  const classes = useStyles();

  return (
    <TableContainer className={[className, classes.table].join(' ')}>
      <MuiTable>
        {title && (
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length + (actions ? 1 : 0)}>
                {title}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              {columns.map((_, j) => (
                <TableCell key={j} component="td" scope="row">
                  {row[j]}
                </TableCell>
              ))}
              {actions?.[i] && (
                <TableCell component="td" scope="row" width="48px">
                  <a
                    href={actions[i]}
                    target="_blank"
                    variant="body1"
                    rel="noreferrer"
                  >
                    <KeyboardArrowRight fontSize="large" />
                  </a>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
