import React, { Fragment } from 'react';
// import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { TitleWithLine, DownloadLineSkin, TextButtonLineSkin } from 'shared';

import Slider from '../components/Slider';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: '4rem',
    textTransform: 'uppercase',
  },
  table: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '14pt',
    color: theme.palette.primary.main,
    fontFamily: 'Druk Wide Medium, Trade Gothic LT STD, sans-serif',
    textTransform: 'uppercase',
    '@media (max-width: 900px)': {
      fontSize: '12pt',
    },
    '@media (max-width: 600px)': {
      fontSize: '11pt',
    },
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    paddingTop: '2rem',
  },
}));

const SlideshowAndDocuments = ({
  title,
  text,
  documents,
  sections,
  ...sliderProps
}) => {
  const classes = useStyles();
  // const { t } = useTranslation();

  return (
    <Fragment>
      <Slider {...sliderProps} textCentered />
      <Container className={classes.container}>
        {/* {title && (
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        )} */}
        {text.map(({ title, richText }, i) => (
          <Fragment key={i}>
            {title && (
              <Typography variant="h5" className={classes.subtitle}>
                {title}
              </Typography>
            )}
            <Typography
              className={classes.textBody}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: richText }}
            ></Typography>
          </Fragment>
        ))}
        {sections &&
          sections.map((section, i) => (
            <div className={classes.rowContainer}>
              {section.title && <TitleWithLine
                key={JSON.stringify(section)}
                title={section.title}
              ></TitleWithLine>
              }
              {section.elements.buttons &&
                section.elements.buttons.map((doc, i) => (
                  <TextButtonLineSkin
                    key={JSON.stringify(doc)}
                    data={doc}
                  ></TextButtonLineSkin>
                ))}
              {section.elements.documents.map((doc, i) => (
                <DownloadLineSkin
                  key={JSON.stringify(doc)}
                  data={doc}
                ></DownloadLineSkin>
              ))}
            </div>
          ))}
      </Container>
    </Fragment>
  );
};

export default SlideshowAndDocuments;
