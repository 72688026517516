import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Slider from '../components/Slider';
import Quote from '../components/Quote';
import { TextButtonLineSkin, TitleWithLine, DownloadLineSkin } from 'shared';
import styles from './index.module.scss';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '14pt',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  quote: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    margin: 'auto',
    width: '75%',
  },
  twocolumns: {
    columnCount: '2',
  },
  titledownload: {
    textTransform: 'uppercase',
  },
  titleSection: {
    display: 'flex',
    width: '1180px',
    margin: '100px auto',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  line: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    borderBottom: '1px solid #003a78',
    '&:hover': {
      backgroundColor: '#e3e3e3',
    },
  },
  documentTitle: {
    color: theme.palette.primary.main,
  },
}));

const SlideshowAndLinks = ({
  title,
  text,
  ctas,
  quotes,
  twocolumns,
  documents,
  sections,
  hasButton,
  ...sliderProps
}) => {
  const classes = useStyles();
  hasButton =
    Object.entries(hasButton).length > 0
      ? hasButton
      : { hasButton: { section: false, hasButton: false, buttonText: '' } };
  return (
    <Fragment>
      <Slider {...sliderProps} textCentered />
      <Container className={classes.container}>
        {quotes.map(
          // TODO: Change this approach
          ({ text, author }, i) => (
            <Quote
              text={text}
              author={author}
              key={i}
              className={classes.quote}
            />
          ),
        )}
        {text.map(({ title, richText }, i) => (
          <Fragment key={i}>
            {title && (
              <Typography variant="h5" className={classes.subtitle}>
                {title}
              </Typography>
            )}
            <Typography
              className={classes.textBody}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: richText }}
            ></Typography>
          </Fragment>
        ))}
        {hasButton.hasButton.section && (
          <div className={styles.buttonContainer}>
            <a href={hasButton.hasButton.link} className={styles.blueButton}>
              {hasButton.hasButton.buttonText}
            </a>
          </div>
        )}
        <Grid spacing={1} className={classes.twocolumns}>
          {twocolumns.map(({ title, richText }, i) => (
            <Fragment key={i}>
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </Fragment>
          ))}
        </Grid>
        {sections.map((section, i) => (
          <div>
            {section.elements.buttons &&
              !hasButton.hasButton.hasButton &&
              section.elements.buttons.map((doc, i) => (
                <TextButtonLineSkin
                  key={JSON.stringify(doc)}
                  data={doc}
                ></TextButtonLineSkin>
              ))}
            {section.elements.documents && hasButton.hasButton.section && (
              <div key={JSON.stringify(section)}>
                {section.title && (
                  <TitleWithLine title={section.title}></TitleWithLine>
                )}
                {section.elements.documents.map((doc, i) => (
                  <DownloadLineSkin
                    key={JSON.stringify(doc)}
                    data={doc}
                  ></DownloadLineSkin>
                ))}
              </div>
            )}
            {hasButton.hasButton.buttonText !== "" && !hasButton.hasButton.section && (
              <div className={styles.buttonContainer}>
                <a href={'/projects'} className={styles.blueButton}>
                  {hasButton.hasButton.buttonText}
                </a>
              </div>
            )}
          </div>
        ))}
      </Container>
    </Fragment>
  );
};

export default SlideshowAndLinks;
