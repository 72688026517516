import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import { Box, Collapse, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';

const ListItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(MuiListItem);

const useStyles = makeStyles((theme) => ({
  subMenuListItems: {
    '&:before': {
      border: 'none',
      content: '" " !important',
      padding: '20px',
    },
  },
}));

function SubMenuItem({ title, closeCallback, subMenuList, isPff = false }) {
  const [stateOpen, setStateState] = React.useState({ active: '' });

  const ListItemCustom = ({ children, onMouseLeave, onMouseEnter }) => {
    return (
      <ListItem onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        {children}
      </ListItem>
    );
  };
  const classes = useStyles();
  const updateState = (keyName) => {
    setStateState({ active: keyName });
  };

  return (
    <Box pt="1rem">
      <Typography variant="h5" style={{ color: isPff ? 'white' : ''}}>{title}</Typography>
      <Grid container direction="row">
        <Grid item xs={12}>
          <List component="nav">
            {subMenuList.map((curr, idx) => (
              <ListItemCustom
                key={idx}
                onMouseEnter={() => {
                  updateState(curr.keyName);
                }}
              >
                <Link
                  onClick={() => {
                    closeCallback(false);
                  }}
                  target={curr.target || '_self'}
                  to={curr.active == false ? '' : curr.linkTo}
                  style={{ color: curr.active != false ? 'white' : '#bebebe'}}
                >
                  {curr.title}
                </Link>
                {curr.keyName !== undefined &&
                curr.subMenuListItems !== undefined ? (
                  <span></span>
                ) : (
                  <span className={classes.subMenuListItems}></span>
                )}
              </ListItemCustom>
            ))}
          </List>
        </Grid>
        <Grid item xs={4}>
          {subMenuList.map((curr, idx) => {
            return curr.keyName !== undefined ? (
              <Collapse
                key={idx}
                in={stateOpen.active === curr.keyName}
                timeout="auto"
                unmountOnExit
              >
                <List component="nav">
                  {curr.subMenuListItems &&
                    curr.subMenuListItems.map((currInner, idx) => (
                      <ListItemCustom key={idx}>
                        <Link
                          onClick={() => {
                            closeCallback(false);
                          }}
                          to={currInner.linkTo}
                        >
                          {currInner.title}
                        </Link>
                      </ListItemCustom>
                    ))}
                </List>
              </Collapse>
            ) : null;
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

SubMenuItem.propTypes = {
  title: PropTypes.string,
  closeCallback: PropTypes.func,
  subMenuList: PropTypes.arrayOf(PropTypes.object),
};

export default SubMenuItem;
