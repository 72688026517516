import { useState } from 'react';

export default function useSlider({ imageData }) {
  const [currentImage, setCurrentImage] = useState(0);

  const slideHandler = (value) => {
    setCurrentImage(value);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage) =>
      currentImage - 1 < 0 ? imageData.length - 1 : currentImage - 1,
    );
  };

  const handleNext = () => {
    setCurrentImage((currentImage) => (currentImage + 1) % imageData.length);
  };

  return { currentImage, slideHandler, handlePrev, handleNext };
}
