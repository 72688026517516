import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import Slider from '../../components/Slider';
import Accordion from '../../components/Accordion';
import Table from '../../components/Table';
import Tabs from '../../components/Tabs';
import useTable from './hooks/useTable';
import useTabs from './hooks/useTabs';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
  },
  tabsContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    width: '100%',
    backgroundColor: theme.palette.secondary.dark,
    maxWidth: 'unset',
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  tabPanel: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    maxWidth: '1180px',
    width: '100%',
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const SlideshowAndAccordions = ({
  title,
  text,
  accordions,
  ...sliderProps
}) => {
  const classes = useStyles();

  const tableData = useTable({ accordions });
  const tabsData = useTabs();

  return (
    <Fragment>
      <Slider {...sliderProps} textCentered />
      <Container className={classes.textContainer}>
        {/* <Typography variant="h2" className={classes.title}>
          {title}
        </Typography> */}
        {text.map(({ title, richText }, i) => (
          <Fragment key={i}>
            {title && (
              <Typography variant="h5" className={classes.subtitle}>
                {title}
              </Typography>
            )}
            <Typography
              className={classes.textBody}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: richText }}
            ></Typography>
          </Fragment>
        ))}
      </Container>
      <Container className={classes.tabsContainer}>
        <Tabs {...tabsData} />
        {tabsData.selected === 0 && (
          <Box className={classes.tabPanel}>
            {tableData.map(({ title, ...tableProps }, i) => {
              return (
                <Accordion title={title} key={i}>
                  <Table {...tableProps} />
                </Accordion>
              );
            })}
          </Box>
        )}
      </Container>
    </Fragment>
  );
};

export default SlideshowAndAccordions;
