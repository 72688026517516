import { useMemo } from 'react';

export default function useTable({ accordions }) {
  const tableData = useMemo(() => {
    const columns = ['name'];
    return accordions.map((accordion) => {
      const rows = accordion.elements.map((record) => [record.name]);
      const actions = accordion.elements.map((record) => record.id);

      return { title: accordion.title, rows, actions, columns };
    });
  }, [accordions]);

  return tableData;
}
