import { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useAnimationOnHover = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    if (isMobile) return;
    setAnimationClass('box__inner--hovered--end');
    setHovered(true);
  };
  const handleMouseLeave = () => {
    if (isMobile) return;
    setHovered(false);
    setAnimationClass('box__inner--hovered--start');
  };

  const [animationClass, setAnimationClass] = useState(
    'box__inner--hovered--start',
  );

  useEffect(() => {
    if (isMobile) {
      setHovered(true);
      setAnimationClass('box__inner--hovered--end');
    } else {
      setHovered(false);
      setAnimationClass('box__inner--hovered--start');
    }
  }, [isMobile]);

  return {
    hovered,
    animationClass,
    handleMouseLeave,
    handleMouseOver,
  };
};
