import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  slider__image: {
    minHeight: '650px',
    position: 'relative',
    margin: '0px 0px',
    padding: '0px 0px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    boxPack: 'center',
    justifyContent: 'center',
    opacity: 0,
    '& > img': {
      objectFit: 'cover',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'block',
      margin: 'auto auto',
      opacity: (props) => props.opacity,
    },
    '@media screen and (max-width: 400px)': {
      minHeight: '150px',
    },
  },
}));
const SliderImage = ({ src }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <img className={classes.slider__image} src={src} alt="Slide" />
    </Fragment>
  );
};

export default SliderImage;
