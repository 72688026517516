import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: (props) => props.color,
    padding: (props) => props.padding,
  },
}))(MuiAccordionDetails);

export default AccordionDetails;
