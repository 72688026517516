import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Sitemap from './Sitemap';

const useStyles = makeStyles((theme) => ({
  logo: {
    '& img': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'unset',
      },
    },
  },
  footerBox: {
    backgroundColor: theme.palette.secondary.dark,
    marginTop:'50px'
  },
}));

function Footer({ logo, siteMapData }) {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.footerBox}>
      <Sitemap siteMapData={siteMapData} />
      <Box
        my="2rem"
        display="flex"
        flexDirection="column"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Container>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Box className={classes.logo}>
                <Link
                  target="_blank"
                  to={{
                    pathname: 'https://www.fincantieri.com/',
                  }}
                >
                  <img
                    src={logo}
                    alt="Company logo"
                    // width={544}
                    height={34}
                  ></img>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
