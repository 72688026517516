import styles from './ViewLineSkin.module.scss';
import React from 'react';

export default function ViewLineSkin({ data }) {
  return (
    <div className={styles.row}>
      <p className={styles.documentTitle}>{data.title}</p>
      <button className={styles.button}>{data.button}</button>
    </div>
  );
}
