import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import Slider from '../components/Slider';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '2rem 0'
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  placeholder: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xl')]: {
      width: '425px',
      height: '425px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: '35%',
    },
    width: '100%',
    aspectRatio: '1/1',
  },
}));

const SlideshowAndBottomImage = ({ title, text, images, ...sliderProps }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Slider {...sliderProps} textCentered />
      <Container className={classes.container}>
        <Box>
          {text.map(({ title, richText }, i) => (
            <Fragment key={i}>
              {title && (
                <Typography variant="h5" className={classes.subtitle}>
                  {title}
                </Typography>
              )}
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </Fragment>
          ))}
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          {images.map((image, i) => (
            <img key={i} src={image.src} alt={image.alt} />
          ))}
        </Box>
      </Container>
    </Fragment>
  );
};

export default SlideshowAndBottomImage;
