import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Helmet from '../components/Helmet';

import SlideshowAndText from './SlideshowAndText';
import SlideshowAndDocuments from './SlideshowAndDocuments';
import SlideshowAndLinks from './SlideshowAndLinks';
import SlideshowAndAccordions from './SlideshowAndAccordions';
import Case from './Case';
import SlideshowAndSideImage from './SlideshowAndSideImage';
import TextAndBottomImage from './TextAndBottomImage';
import SlideshowAndTopImage from './SlideshowAndTopImage';
import SlideshowAndCenterImage from './SlideshowAndCenterImage';
import SlideshowAndBottomImage from './SlideshowAndBottomImage';
import SlideshowAndTopImageAndLinks from './SlideshowAndTopImageAndLinks';
import SlideshowAndLinksAndBoxes from './SlideshowAndLinksAndBoxes';
import TextAndBoxes from './TextAndBoxes';
import SlideshowAndLinksAndDocuments from './SlideshowAndLinksAndDocuments';
import TextAndTopImage from './TextAndTopImage';
import SlideshowAndBoxes from './SlideshowAndBoxes';
import TopImageAndLinks from './TopImageAndLinks';
import SuppliersPage from './SuppliersPage';
import ManagementPage from './ManagementPage';

import usePageTitle from '../hooks/usePageTitle';
import useScrollToTop from '../hooks/useScrollToTop';

import getPageContent from '../utils/getPageContent';

import {
  SLIDESHOW_AND_DOCUMENTS,
  SLIDESHOW_AND_TEXT,
  SLIDESHOW_AND_LINKS,
  SLIDESHOW_AND_ACCORDION,
  CASE,
  SLIDESHOW_AND_SIDE_IMAGE,
  SLIDESHOW_AND_BOTTOM_IMAGE,
  TEXT_AND_BOTTOM_IMAGE,
  SLIDESHOW_AND_TOP_IMAGE,
  SLIDESHOW_AND_CENTER_IMAGE,
  SLIDESHOW_AND_TOP_IMAGE_AND_LINKS,
  TEXT_AND_BOXES,
  SLIDESHOW_AND_LINKS_AND_DOCUMENTS,
  SLIDESHOW_AND_LINKS_AND_BOXES,
  TEXT_AND_TOP_IMAGE,
  SLIDESHOW_AND_BOXES,
  IMAGE_AND_LINKS,
  IMAGE_AND_DOCUMENTS,
  SUPPLIERS_PAGE,
  MANAGEMENT_PAGE,
} from '../constants';
import TopImageAndDocuments from './TopImageAndDocuments';

const BasePage = ({ data, pageType, children, projectsData, withFixedImage, hasBoxText, hasCta, ...hasButton }) => {
  useScrollToTop();
  const title = usePageTitle();

  const {
    i18n: { language },
  } = useTranslation();

  const renderPage = useCallback(() => {
    if (!pageType) {
      return children;
    }
    const projects = projectsData ? projectsData[language] : null;
    const pageContent = getPageContent(data[language]);

    switch (pageType) {
      case SLIDESHOW_AND_TEXT:
        return <SlideshowAndText title={title} {...pageContent} withFixedImage={withFixedImage} hasBoxText={hasBoxText} hasCta={hasCta} />;
      case SLIDESHOW_AND_DOCUMENTS:
        return <SlideshowAndDocuments title={title} {...pageContent} />;
      case SLIDESHOW_AND_LINKS:
        return <SlideshowAndLinks title={title} {...pageContent} hasButton={hasButton}/>;
      case SLIDESHOW_AND_ACCORDION:
        return <SlideshowAndAccordions title={title} {...pageContent} />;
      case CASE:{
        return <Case datalist={projects} {...pageContent} />;
      }
 
      case SLIDESHOW_AND_SIDE_IMAGE:
        return <SlideshowAndSideImage title={title} {...pageContent} />;
      case SLIDESHOW_AND_BOTTOM_IMAGE:
        return <SlideshowAndBottomImage title={title} {...pageContent} />;
      case TEXT_AND_BOTTOM_IMAGE:
        return <TextAndBottomImage title={title} {...pageContent} />;
      case SLIDESHOW_AND_TOP_IMAGE:
        return <SlideshowAndTopImage title={title} {...pageContent} />;
      case SLIDESHOW_AND_CENTER_IMAGE:
        return <SlideshowAndCenterImage title={title} {...pageContent} />;
      case SLIDESHOW_AND_TOP_IMAGE_AND_LINKS:
        return <SlideshowAndTopImageAndLinks title={title} {...pageContent} />;
      case TEXT_AND_BOXES:
        return <TextAndBoxes title={title} {...pageContent} />;
      case SLIDESHOW_AND_LINKS_AND_DOCUMENTS:
        return <SlideshowAndLinksAndDocuments title={title} {...pageContent} />;
      case SLIDESHOW_AND_LINKS_AND_BOXES:
        return <SlideshowAndLinksAndBoxes title={title} {...pageContent} />;
      case TEXT_AND_TOP_IMAGE:
        return <TextAndTopImage title={title} {...pageContent} />;
      case SLIDESHOW_AND_BOXES:
        return <SlideshowAndBoxes title={title} {...pageContent} />;
      case IMAGE_AND_LINKS:
        return <TopImageAndLinks title={title} {...pageContent} />;
      case IMAGE_AND_DOCUMENTS:
        return <TopImageAndDocuments title={title} {...pageContent} />;
      case SUPPLIERS_PAGE:
        return <SuppliersPage title={title} {...pageContent} />;
      case MANAGEMENT_PAGE:
        return <ManagementPage title={title} {...pageContent} />;
      default:
        return children;
    }
  }, [children, data, pageType, title, language]);

  return (
    <Fragment>
      <Helmet pageTitle={title} />
      {renderPage()}
    </Fragment>
  );
};

export default BasePage;
