import React, { Fragment } from 'react';
// import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import Slider from '../components/Slider';
import CTA from '../components/CallToAction';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    maxWidth: '1180px',
  },
  title: {
    padding: '0 4rem',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    padding: '0 4rem',
    fontSize: '14pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  textBody: {
    padding: '0 4rem',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const SlideshowAndLinksAndDocuments = ({
  title,
  text,
  ctas,
  documents,
  ...sliderProps
}) => {
  const classes = useStyles();
  // const { t } = useTranslation();

  return (
    <Fragment>
      <Slider {...sliderProps} textCentered />
      <Container className={classes.container}>
        {text.map(({ title, richText }, i) => (
          <Fragment key={i}>
            {title && (
              <Typography variant="h5" className={classes.subtitle}>
                {title}
              </Typography>
            )}
            <Typography
              className={classes.textBody}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: richText }}
            ></Typography>
          </Fragment>
        ))}
        {ctas.map((cta, i) => (
          <CTA key={i} title={cta.title} link={cta.link} text={cta.text} />
        ))}
      </Container>
    </Fragment>
  );
};

export default SlideshowAndLinksAndDocuments;
