import styles from './TitleWithFixedImage.module.scss';
import React from 'react';
import { useRef, useEffect, useState } from 'react';

export default function TitleWithFixedImage({
  src,
  srcMob,
  hasSign,
  alt,
  title,
  innerHtml,
  isTitleHtml,
  video,
}) {
  const innerHtmlRef = useRef(null);
  const titleInnerHtml = useRef(null);
  const [top, setTop] = useState('50%');
  const [opacity, setOpacity] = useState(0);
  const signRef = useRef(null);
  const [signTop, setSignTop] = useState(0);

  // State to store the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(0.9);
      setTop('75%');
    }, 100);
  }, []);

  useEffect(() => {
    if (innerHtmlRef.current && innerHtml) {
      innerHtmlRef.current.innerHTML = innerHtml;
    }
    if (titleInnerHtml.current && isTitleHtml) {
      titleInnerHtml.current.innerHTML = isTitleHtml;
    }
  }, [innerHtml, isTitleHtml]);

  useEffect(() => {
    if (signRef.current) {
      setSignTop(signRef.current.clientWidth / 1.5);
    }
  }, [hasSign]);

  return (
    <div className={styles.mainContainer}>
      {hasSign && (
        <div
          ref={signRef}
          className={styles.sign}
          style={{
            top: windowWidth > 1309 ? `${signTop}px` : `${signTop + 20}px`,
          }}
        >
          {hasSign}
        </div>
      )}
      {video ? (
        <video src={video}></video>
      ) : (
        <img
          src={
            windowWidth > 1024 || (windowWidth < 1024 && !srcMob) ? src : srcMob
          }
          alt={alt}
        />
      )}

      {!innerHtml ? (
        title && (
          <div
            style={{ top: top, opacity: opacity }}
            className={styles.titleContainer}
          >
            <h2 className={styles.title} ref={titleInnerHtml}>
              {isTitleHtml ? '' : title}
            </h2>
          </div>
        )
      ) : (
        <div
          style={{ top: top, opacity: opacity }}
          className={styles.innerHtmlContainer}
          ref={innerHtmlRef}
        ></div>
      )}
      {video ? (
        <div className={classes.playButton} onClick={handleOpen}>
          &#9658;
        </div>
      ) : null}
    </div>
  );
}
