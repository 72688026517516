import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    fontSize: '18pt',
    width: 'fit-content',
    border: '1px solid ' + theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  title: {
    with: '100%',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingRight: '10px',
    fontSize: '16px',
    fontFamily: 'Druk Wide Medium',
    textTransform: 'uppercase',
  },
  subTitle: {
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '8px',
    fontSize: '12pt',
    textTransform: 'uppercase',
    fontFamily: 'Fraktion Mono !important',
    '& > ul': {
      paddingLeft: '16px',
    },
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    minHeight: '160px',
    padding: '10px 5px',
  },
  properties: {
    padding: '10px',
    fontSize: '7pt',
    color: '#000',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  topSection: {
    height: 'fit-content',
    borderBottom: '1px solid #000',
  },
  logo: {
    position: 'relative',
    bottom: '140px',
    left: '20px',
    width: '170px',
    height: '50px',
  },
  goButton: {
    position: 'relative',
    bottom: '170px',
    left: '205px',
    width: '100px',
    height: '30px',
    backgroundColor: '#003a78',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#014996',
    },
  },
}));

const Entity = (props) => {
  const { title, properties, image, icon, link } = props.props;

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {(image || icon) && <Grid item xs={12} className={classes.topSection}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={image}
            alt="img"
            style={{ width: '100%', height: '100%' }}
          />
        </div>

        {icon && <img src={icon} alt="img" className={classes.logo} />}
        {link && (
          <a href={link} target="_blank">
            <button
              onclick="window.location.href="
              className={classes.goButton}
            >
              Scopri di più
            </button>
          </a>
        )}
      </Grid> }
      <div className={classes.detailsContainer}>
        <div
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
        <div
          className={classes.subTitle}
          dangerouslySetInnerHTML={{ __html: properties }}
        ></div>
      </div>
    </div>
  );
};

export default Entity;
