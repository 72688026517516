import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import SliderImage from './SliderImage';
import SliderButtons from './SliderButtons';
import useSlider from './useSlider';
import styles from './index.module.scss';
export var interval;

function Slider({
  imageData,
  timer = 10,
  largeText,
  mediumText,
  textCentered,
  isProjects,
  handleOpen,
  height,
  hasSign,
}) {
  const { currentImage, ...controls } = useSlider({ timer, imageData });
  const detailText =
    imageData.length > 0 ? imageData[currentImage].richText : [];

  const [top, setTop] = useState('10%');
  const [opacity, setOpacity] = useState(0);

  // State to store the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(0.9);
      setTop('25%');
    }, 100);
  }, []);

  if (!Array.isArray(imageData) || imageData.length <= 0) {
    return null;
  }

  return (
    <div className={styles.top__container}>
      <Box className={styles.slider}>
        {imageData.map((img, i) => (
          <>
            <Fragment key={i}>
              <Box
                style={{
                  opacity: i === currentImage ? 1 : 0,
                  animationName:
                    i === currentImage && imageData.length > 1
                      ? 'slider-zoom'
                      : 'unset',
                  animationDuration: i === currentImage ? '20s' : 'unset',
                  animationTimingFunction:
                    i === currentImage ? 'ease' : 'unset',
                }}
                className={[
                  styles.slider__image,
                  'slider__image__container',
                ].join(' ')}
                width="100%"
                height="100%"
              >
                <div
                  className={styles.sign}
                >
                  {img.hasSign}
                </div>
                <SliderImage
                  src={
                    windowWidth > 1024 || (windowWidth < 1024 && !img.imageMob)
                      ? img.image
                      : img.imageMob
                  }
                  key={i}
                />
              </Box>

              <Box
                className={styles.slider__nav}
                width="100%"
                height="100%"
                style={{ opacity: i === currentImage ? 1 : 0 }}
              >
                {!isProjects ? (
                  <Box
                    width="100%"
                    height="100%"
                    style={{ top: top, opacity: opacity }}
                    className={[styles.slider__text__title]}
                    dangerouslySetInnerHTML={{ __html: img.richTextTitle }}
                  ></Box>
                ) : (
                  <>
                    <Box
                      width="100%"
                      height="100%"
                      style={{ opacity: opacity, position:'relative' }}
                      className={[styles.slider__text__title__projects]}
                      dangerouslySetInnerHTML={{ __html: img.richTextTitle }}
                    ></Box>
                  </>
                )}
              </Box>
            </Fragment>
          </>
        ))}
        {imageData.length > 1 && (
          <SliderButtons
            imageData={imageData}
            currentImage={currentImage}
            {...controls}
          />
        )}
      </Box>
      {detailText && (
        <Box className={styles.text__container} width="100%">
          {
            <Box
              className={styles.slider__text}
              dangerouslySetInnerHTML={{ __html: detailText }}
            ></Box>
          }
        </Box>
      )}
    </div>
  );
}

Slider.propTypes = {
  imageData: PropTypes.arrayOf(PropTypes.object),
  textBottom: PropTypes.string,
  textSlider: PropTypes.string,
  timer: PropTypes.number,
};

export default Slider;
