export const GROUP_CONTENT_TYPE = 'group';
export const FINCANTIERI_INFRASTRUCTURE_CONTENT_TYPE =
  'fincantieriinfrastructure';
export const MARITIME_CONTENT_TYPE = 'operemarittime';
export const FINSO_CONTENT_TYPE = 'finso';
export const SOF_CONTENT_TYPE = 'sof';

export const SLIDESHOW_AND_TEXT = 'slideshowandtext';
export const SLIDESHOW_AND_DOCUMENTS = 'slideshowanddocuments';
export const SLIDESHOW_AND_BOXES = 'slideshowandboxes';
export const SLIDESHOW_AND_LINKS = 'slideshowandlinks';
export const SLIDESHOW_AND_LINKS_AND_DOCUMENTS =
  'slideshowandlinksanddocuments';
export const SLIDESHOW_AND_LINKS_AND_BOXES = 'slideshowandlinksandboxes';
export const SLIDESHOW_AND_ACCORDION = 'slideshowandaccordion';
export const SLIDESHOW_AND_SIDE_IMAGE = 'slideshowandsideimage';
export const SLIDESHOW_AND_BOTTOM_IMAGE = 'slideshowandbottomimage';
export const SLIDESHOW_AND_TOP_IMAGE = 'slideshowandtopimage';
export const SLIDESHOW_AND_CENTER_IMAGE = 'slideshowandcenterimage';
export const SLIDESHOW_AND_TOP_IMAGE_AND_LINKS = 'slideshowandtopimageandlinks';
export const TEXT_AND_BOTTOM_IMAGE = 'textandbottomimage';
export const TEXT_AND_TOP_IMAGE = 'textandtopimage';
export const TEXT_AND_BOXES = 'textandboxes';
export const IMAGE_AND_LINKS = 'imageandlinks';
export const IMAGE_AND_DOCUMENTS = 'imageanddocuments';
export const IMAGE_WITH_BUTTON = 'imagewithbutton';
export const SUPPLIERS_PAGE = 'supplierspage';
export const MANAGEMENT_PAGE = 'managementpage';
export const CASE = 'case';
