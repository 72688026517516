import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import Slider from '../components/Slider';
import Box from '../components/Box';
import CTA from '../components/CallToAction';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const SlideshowAndLinksAndBoxes = ({
  title,
  text,
  ctas,
  boxes,
  ...sliderProps
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Slider {...sliderProps} textCentered />
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        {text.map(({ title, richText }, i) => (
          <Fragment key={i}>
            {title && (
              <Typography variant="h5" className={classes.subtitle}>
                {title}
              </Typography>
            )}
            <Typography
              className={classes.textBody}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: richText }}
            ></Typography>
          </Fragment>
        ))}
        {ctas.map((cta, i) => (
          <CTA key={i} title={cta.title} link={cta.link} text={cta.text} />
        ))}
        <Grid
          container
          justify="center"
          direction="row"
          wrap="wrap"
          spacing={3}
          className={classes.grid}
        >
          {boxes.map((box, i) => {
            const { imageTitle, image, cta } = box;
            return (
              <Grid item xs={12} sm={6} md={12 / boxes.length} key={i}>
                <Box imageTitle={imageTitle} imageBox={image} cta={cta} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default SlideshowAndLinksAndBoxes;
