import styles from './TitleWithLine.module.scss';
import React from 'react';


export default function TitleWithLine({ title }) {
  return (
    <div className={styles.titleSection}>
      <h2 className={styles.lineTitle}>{title}</h2>
      <div className={styles.line}></div>
    </div>
  );
}
