import React from 'react';

import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  quote: {
    fontSize: '18pt',
    color: theme.palette.primary.main,
    fontFamily: 'Druk Wide Medium',
    fontStyle: 'italic',
  },
  author: {
    fontSize: '14pt',
    color: theme.palette.secondary.main,
    fontStyle: 'italic',
  },
}));

const Quote = ({ text, author, className }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <q className={classes.quote}>{text}</q>
      {author && <span className={classes.author}>{author}</span>}
    </div>
  );
};

export default Quote;
