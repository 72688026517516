import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme) => ({
  gallery__bottom: {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 16px 0px 16px',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    right: 0,
    [theme.breakpoints.up('xl')]: {
      right: 'calc(50% - (1310px / 2))',
    },
  },
  gallery__bottom__controls: {
    color: theme.palette.tertiary.main,
    '&:hover': {
      cursor: 'pointer',
    },
    '&--current': {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
    },
  },
}));

const Buttons = ({ handlePrev, handleNext }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      className={classes.gallery__bottom}
      position="absolute"
      bottom="0"
      gridGap="1rem"
    >
      <Box>
        <ArrowLeftIcon
          className={classes.gallery__bottom__controls}
          fontSize="large"
          onClick={handlePrev}
        />
        <ArrowRightIcon
          className={classes.gallery__bottom__controls}
          fontSize="large"
          onClick={handleNext}
        />
      </Box>
    </Box>
  );
};

export default Buttons;
