import React, { Fragment } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MenuMobile from './MenuMobile/MenuMobile';
import { Grid, Hidden, Box } from '@material-ui/core';
import MuiCollapse from '@material-ui/core/Collapse';

import LanguageSelector from './LanguageSelector/LanguageSelector';

const Collapse = withStyles((theme) => ({
  container: {
    width: '100%',
  },
}))(MuiCollapse);

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'unset',
    },
  },
  header: {
    position: 'fixed',
    width:'100%',
    top: '0',
    marginBottom: '70px',
    backgroundColor: theme.palette.tertiary.main,
    padding: '5px 5px',
    height: '70px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 99999,
    '& a': {
      display: 'flex',
    },
  },
  submenu: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.main,
    width: '100vw',
    textTransform: 'uppercase',
    fontSize: '15px',
    display: 'flex',
    flexDirection: 'row',
    top: '70px',

    position: 'fixed',

    zIndex: 99999,
    '& a': {
      color: theme.palette.tertiary.main,
      textDecoration: 'none',
      margin: 0,
      padding: 0,
    },
    '& ul': {
      color: theme.palette.tertiary.main,
      textDecoration: 'none',
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    '& li': {
      color: theme.palette.tertiary.main,
      textDecoration: 'none',
      margin: 0,
      padding: 5,
      width: '100%',
    },
    '& li a': {
      padding: '5px',
      width: '100%',
    },
    '& li span:before': {
      padding: '10px',
      border: 'none',
      content: '"+"',
    },
  },
  bottomMenu: {
    padding: '10px 10px',
    marginRight: '10px',
    gap: '4px',
  },
  social: {
    '& div': {
      opacity: '.75',
      '&:hover': {
        opacity: 1,
      },
    },
  },
  collapse: {
    paddingRight: '10px',
  },
}));

const HeaderMobile = ({ logoSmall, menuMobileData }) => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);

  // WORKAROUND: Remove this piece of code when the site is ready
  const location = useLocation();
  const path = location.pathname;

  const isPffLogo = logoSmall.includes('pff');

  return (
    <Fragment>
      <Hidden xlUp>
        <Box className={classes.header}>
          <NavLink exact to="/">
            <img
              className={isPffLogo ? '' : classes.logo}
              src={logoSmall}
              alt="logo"
              width={isPffLogo ? 110 : 544}
              height={isPffLogo ? 45 : 34}
            ></img>
          </NavLink>
          <MenuIcon
            fontSize="large"
            color="primary"
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          />
        </Box>
        {path !== '/_pages' && (
          <Box className={classes.submenu}>
            <Collapse
              in={openMenu}
              timeout="auto"
              unmountOnExit
              className={classes.collapse}
            >
              <MenuMobile
                openClose={setOpenMenu}
                state={openMenu}
                menuMobileData={menuMobileData}
              />
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                className={classes.bottomMenu}
              >
                <Grid item>
                  <LanguageSelector />
                </Grid>
                <Grid item>
                  <Link
                    target="_blank"
                    to={{
                      pathname:
                        'https://www.linkedin.com/company/fincantieri-infrastructure/',
                    }}
                    className={classes.social}
                  >
                    <div id="linkedin-icon" />
                  </Link>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        )}
      </Hidden>
    </Fragment>
  );
};

export default HeaderMobile;
