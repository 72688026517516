import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Buttons from './Buttons';
import useSlider from './useSlider';

export var interval;

const useStyles = makeStyles((theme) => ({
  slider: {
    display: 'flex',
    padding: '0px 0px',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    margin: 0,
    maxWidth: '100%',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  gallery__image: {
    position: 'absolute',
    margin: 'auto',
    maxWidth: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    boxPack: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'opacity 350ms ease-out',
  },
  gallery__bottom: {
    height: '50px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 15px',
    alignItems: 'center',
  },
}));
function PhotoGallery({ imageData }) {
  const classes = useStyles();
  const { currentImage, ...controls } = useSlider({
    imageData,
  });

  if (!Array.isArray(imageData) || imageData.length <= 0) {
    return null;
  }

  return (
    <Box className={classes.slider}>
      {imageData.map((img, i) => (
        <Box
          key={i}
          style={{
            opacity: i === currentImage ? '1' : '0',
          }}
          className={[classes.gallery__image, 'gallery__image__container'].join(
            ' ',
          )}
          width="100%"
          height="100%"
        >
          <img className={classes.gallery__image} src={img.image} alt="Slide" />
        </Box>
      ))}
      {imageData.length > 1 && <Buttons {...controls} />}
    </Box>
  );
}

export default PhotoGallery;
