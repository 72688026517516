import React, { Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cta: {
    textDecoration: 'none',
    color: theme.palette.tertiary.main,
    '&:visited': {
      color: theme.palette.tertiary.main,
    },
    fontSize: '12pt',
  },
}));

const CallToAction = ({ title, text, link, className }) => {
  const classes = useStyles();

  return (
    <Fragment>
      {title && (
        <Typography variant="h2" className={classes.subtitle}>
          {title}
        </Typography>
      )}
      <a
        target="_blank"
        rel="noreferrer"
        className={[classes.cta, className].join(' ')}
        href={link}
      >
        <Button variant="contained" disableElevation className={classes.button}>
          {text}
        </Button>
      </a>
    </Fragment>
  );
};

export default CallToAction;
