import { useCallback, useEffect, useState } from 'react';

let intervalRef, timeoutRef;

export default function useSlider({ timer, imageData }) {
  const [currentImage, setCurrentImage] = useState(0);

  const startInterval = useCallback(() => {
    if (timer !== 0) {
      setInterval(() => {
        setCurrentImage(
          (currentImage) => (currentImage + 1) % imageData.length,
        );
      }, timer * 1000);
    }
  }, [imageData.length, timer]);

  const resetSlider = () => {
    if (intervalRef) clearInterval(intervalRef);
    if (timeoutRef) clearTimeout(timeoutRef);
  };

  const slideHandler = (value) => {
    setCurrentImage(value);

    if (timer !== 0) {
      resetSlider();
      timeoutRef = setTimeout(startInterval, timer * 1000);
    }
  };

  const handlePrev = () => {
    setCurrentImage((currentImage) =>
      currentImage - 1 < 0 ? imageData.length - 1 : currentImage - 1,
    );

    if (timer !== 0) {
      resetSlider();
      timeoutRef = setTimeout(startInterval, timer * 1000);
    }
  };

  const handleNext = () => {
    setCurrentImage((currentImage) => (currentImage + 1) % imageData.length);

    if (timer !== 0) {
      resetSlider();
      timeoutRef = setTimeout(startInterval, timer * 1000);
    }
  };

  useEffect(() => {
    if (imageData.length > 1 && timer !== 0) {
      intervalRef = startInterval();
      return () => {
        if (intervalRef) clearInterval(intervalRef);
        if (timeoutRef) clearTimeout(timeoutRef);
      };
    }
  }, [imageData.length, timer, startInterval]);

  return { currentImage, slideHandler, handlePrev, handleNext };
}
