import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Slider from '../components/Slider';
import Box from '../components/Box';
import { TitleAndFixedImage } from '..';
import usePageTitle from '../hooks/usePageTitle';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
  },
  grid: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    padding: '0 4rem',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  subtitle: {
    fontSize: '14pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    padding: '0 4rem',
    textTransform: 'uppercase',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  boxText : {
    fontFamily: 'Trade Gothic LT STD',
    lineHeight: '30px',
    fontSize: '12pt',
    textAlign: 'left',
    padding: '20px 5px',
    marginTop: '20px',
    color: theme.palette.primary.main,
  },
  textBody: {
    padding: '0 4rem',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  twocolumns: {
    columnCount: '2',
    padding: '0 4rem',
    columnGap: '3rem',
  },
  box: {
    padding: '12px',
  },
}));

const SlideshowAndText = ({
  title,
  text,
  boxes,
  twocolumns,
  withFixedImage,
  hasBoxText,
  hasCta,
  ...sliderProps
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      {withFixedImage ? (
        <TitleAndFixedImage title={usePageTitle()} src={withFixedImage} />
      ) : (
        <Slider {...sliderProps} textCentered />
      )}
      <Container className={classes.container}>
        {text.map(({ title, richText }, i) => (
          <Fragment key={i}>
            {title && (
              <Typography variant="h5" className={classes.subtitle}>
                {title}
              </Typography>
            )}
            <Typography
              className={classes.textBody}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: richText }}
            ></Typography>
          </Fragment>
        ))}
        <Grid spacing={1} className={classes.twocolumns}>
          {twocolumns.map(({ title, richText }, i) => (
            <Fragment key={i}>
              {richText && title && (
                <Typography variant="h5" className={classes.subtitle}>
                  {title.toUpperCase()}
                </Typography>
              )}
              {richText && (
                <Typography
                  className={classes.textBody}
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: richText }}
                ></Typography>
              )}
            </Fragment>
          ))}
        </Grid>
      </Container>
      <Grid
        container
        justify="center"
        direction="row"
        wrap="wrap"
        className={classes.grid}
      >
        {boxes?.map((box, idx) => {
          const { title, image, cta, boxText } = box;
          return (
            <Grid item xs={12} sm={6} md={3} key={idx} className={classes.box}>
              <Box key={idx} title={title} imageBox={image} cta={cta} hasCta={hasCta} />
              {hasBoxText ? <p className={classes.boxText}>{boxText}</p> : ''}
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

export default SlideshowAndText;
