import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  dark: {
    background: 'none',
    color: theme.palette.primary.main,
    '& *': {
      color: theme.palette.primary.main,
    },
  },
  button: {
    fontFamily: 'Trade Gothic LT STD',
    color: theme.palette.primary.main,
    '& span': {
      fontFamily: 'Trade Gothic LT STD',
    }
  },
  light: {
    color: theme.palette.tertiary.main,
    background: 'none',
  },
  orange: {
    marginTop:'60px'
  }
}));

function LanguageSelector() {
  const { i18n } = useTranslation('common');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState(i18n.language);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseLang = (langChoosen) => {
    setLang(langChoosen);
    localStorage.setItem('language', langChoosen);
    i18n.changeLanguage(langChoosen);
    handleClose();
  };

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item className={classes.button}>
        <Button
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={isMobile ? classes.light : classes.dark}
        >
          <span>{lang}</span>
          <ChevronDown />
        </Button>
      </Grid>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.orange}
      >
        <MenuItem
          className={classes.button}
          onClick={() => {
            chooseLang('en');
          }}
        >
          EN
        </MenuItem>
        <MenuItem
          className={classes.button}
          onClick={() => {
            chooseLang('it');
          }}
        >
          IT
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default LanguageSelector;
