import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2) + 'px 0',
    fontSize: '40pt',
    width: '100%',
    height: '650px',
    // fontFamily: '"Oswald",sans-serif',
    position: 'relative',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  layer: {
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: 0,
    // backgroundColor: 'rgb(50 109 255 / 50%)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '30pt',
    color: '#fff',
    fontWeight: 300,
    marginBottom: '20px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20pt',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18pt',
    },
  },
  subTitle: {
    fontSize: '10pt',
    color: '#fff',
    fontWeight: 400,
    marginBottom: '10px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '7pt',
    },
  },
  button: {
    padding: '8px 20px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const ImgWithButton = (props) => {
  const { imageBox, text, cta } = props;

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={imageBox[0].src} alt="img" className={classes.img} />
      <div className={classes.layer}>
        <div className="text-center">
          <div
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: text[0]?.richText }}
          ></div>
          <div
            className={classes.subTitle}
            dangerouslySetInnerHTML={{ __html: text[1]?.richText }}
          ></div>
          <a href={cta[0].link} target="_blank">
            <button className={classes.button}>{cta[0].text}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImgWithButton;
