import React from 'react';

import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
    fontFamily: 'Trade Gothic LT STD',
    textTransform: 'none',
    color: theme.palette.tertiary.main,
    fontSize: '14pt',
    background: 'none',
    '& .MuiAccordionSummary-root': {
      backgroundColor: theme.palette.primary.main,
      paddingLeft: (props) => (props.preview ? '0' : '16px'),
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.tertiary.main,
    },
    '& .MuiAccordionSummary-content': {
      margin: (props) => (props.preview ? '8px' : '12px 0'),
    },
  },
  preview: {
    backgroundImage: (props) => props.preview && `url("${props.preview}")`,
    backgroundSize: (props) => props.preview && 'cover',
    backgroundRepeat: (props) => props.preview && 'no-repeat',
    backgroundPosition: (props) => props.preview && 'center center',
  },
}));

const Accordion = ({ preview, title, className, children }) => {
  const classes = useStyles({ preview });

  return (
    <MuiAccordion
      square
      elevation={0}
      className={[className, classes.accordion].join(' ')}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" gridGap="1rem" width="100%" alignItems="center">
          {preview && (
            <Box width="48px" height="48px" className={classes.preview} />
          )}
          {title}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
