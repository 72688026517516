import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const usePageTitle = ({ pageTitle } = {}) => {
  const { t } = useTranslation('pages');

  const location = useLocation();
  const splittedLocation = location.pathname.split('/');
  const title = pageTitle || splittedLocation[splittedLocation.length - 1];

  if (title) {
    return t(`pages:${title}`);
  }

  return 'Home';
};

export default usePageTitle;
