import React, { Fragment } from 'react';
// import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, Container /*, Slide */ } from '@material-ui/core';

import MenuItem from './MenuItem';
import SubMenuItem from './SubMenuItem';

// import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  // logo: {
  //   width: '534px',
  //   transform: 'scale(0.60)',
  //   position: 'absolute',
  //   objectFit: 'contain',
  //   '@media screen and (max-width: 1700px)': {
  //     transform: 'scale(0.4)',
  //   },
  //   '@media screen and (max-width: 1650px)': {
  //     display: 'none',
  //   },
  // },
  navbarClassic: {
    textTransform: 'uppercase',
    fontSize: '8pt',
    '& ul': {
      maxWidth: '1310px',
      marginLeft: 'auto',
      marginRight: 'auto',
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 0,
      '& li': {
        position: 'relative',
      },
      '& li:hover:after': {
        position: 'absolute',
        content: '""',
        height: '5px',
        backgroundColor: theme.palette.primary.main,
        left: '0',
        bottom: '-11px',
        width: '100%',
      },
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& a:visited': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  navbarPff: {
    textTransform: 'uppercase',
    fontSize: '8pt',
    '& ul': {
      maxWidth: '1310px',
      marginLeft: 'auto',
      marginRight: 'auto',
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 0,
      '& li': {
        position: 'relative',
      },
      '& li:hover:after': {
        position: 'absolute',
        content: '""',
        height: '5px',
        backgroundColor: '#e37a00',
        left: '0',
        bottom: '-11px',
        width: '100%',
      },
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& a:visited': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  submenu: {
    color: theme.palette.tertiary.main,
    position: 'absolute',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '15pt',
    '& a': {
      color: theme.palette.tertiary.main,
      textDecoration: 'none',
      fontFamily: 'Trade Gothic LT STD',
      margin: 0,
      padding: 0,
      fontSize: '15pt',
      textTransform: 'initial',
    },
    '& li': {
      color: theme.palette.tertiary.main,
      textDecoration: 'none',
      fontFamily: 'Trade Gothic LT STD',
      margin: 0,
      padding: 0,
      fontSize: '15pt',
      textTransform: 'initial',
    },
    '& li a': {
      padding: '10px',
      width: '100%',
    },
    '& li span:before': {
      border: 'none',
      content: '">"',
      padding: '20px',
    },
    '& .MuiListItem-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.plusLight,
      },
    },
    '& .MuiCollapse-wrapper': {
      '@media screen and (min-width: 1310px)': {
        maxWidth: '1310px',
      },
      maxWidth: '100%',
      margin: 'auto',
    },
  },
}));

function Menu({ logoSmall, menuData }) {
  const classes = useStyles();
  
  const isPff = logoSmall.includes('pff');

  const { t } = useTranslation('menu');
  // const trigger = useScrollTrigger({ disableHysteresis: true });

  const menu = menuData({
    t,
    SubMenuItem,
  });

  return (
    <Fragment>
      <nav className={!isPff ? classes.navbarClassic : classes.navbarPff}>
        <ul>
          {menu.map((curr, idx) => (
            <MenuItem key={idx} {...curr} isPff={isPff ? true : false} />
          ))}
        </ul>
      </nav>
      <Box display="flex" className={classes.submenu} style={{ backgroundColor: !isPff ? '#003a78' : '#e37a00' }}>
        <Container style={{ width: '100%', maxWidth: '100vw', padding: 0 }}>
          {menu.map((curr, idx) => (
            <Collapse
              key={idx}
              in={curr.openCloseValue}
              timeout={{
                enter: 300,
                exit: 300,
              }}
              unmountOnExit
              onMouseEnter={() => {
                curr.openCloseCallback(true);
              }}
              onMouseLeave={() => {
                curr.openCloseCallback(false);
              }}
            >
              <Box>{curr.subMenu}</Box>
            </Collapse>
          ))}
        </Container>
      </Box>
    </Fragment>
  );
}

export default Menu;
