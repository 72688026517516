import styles from './ProjectCard.module.scss';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';

export default function ProjectCard({ project, forSliderFlag = false }) {
  return (
    <a href={`${process.env.PUBLIC_URL}/${project.link}`}>
      <div className={styles.mainContainer} style={{width:forSliderFlag ? '290px' : null}}>
        {project.progress && (
          <div className={styles.progressInfo}>{project.progress}</div>
        )}
        <img src={project.image} />
        <div className={styles.cardInfoBar}>
          <h3>{project.name}</h3>
          <div className={styles.actionBar}>
            <p>{project.location}</p>
            <div className={styles.iconContainer}>
              <a href={`${process.env.PUBLIC_URL}/${project.link}`}>
                <AddIcon className={styles.icon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}
