import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Accordion from '../components/Accordion';
import Table from '../components/Table';
import useTable from '../pages/SlideshowAndAccordions/hooks/useTable';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '100%',
    },
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  textBody: {
    maxWidth: '1180px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  placeholder: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xl')]: {
      width: '425px',
      height: '425px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: '35%',
    },
    width: '100%',
    aspectRatio: '1/1',
  },
  image: {
    width: '100%',
  },
}));

const TextAndBottomImage = ({ title, text, images, accordions }) => {
  const classes = useStyles();
  const tableData = useTable({ accordions });

  return (
    <Fragment>
      <Container className={classes.container}>
        <Box>
          {text.map(({ title, richText }, i) => (
            <Fragment key={i}>
              {title && (
                <Typography variant="h5" className={classes.subtitle}>
                  {title}
                </Typography>
              )}
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </Fragment>
          ))}
        </Box>
        <Box className={classes.textBody}>
          {tableData.map(({ title, ...tableProps }, i) => {
            return (
              <Accordion title={title} key={i}>
                <Table {...tableProps} />
              </Accordion>
            );
          })}
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          {images.map((image, i) => (
            <img
              key={i}
              src={image.src}
              alt={image.alt}
              className={classes.image}
            />
          ))}
        </Box>
      </Container>
    </Fragment>
  );
};

export default TextAndBottomImage;
