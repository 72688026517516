import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTabs() {
  const [selected, setSelected] = useState(0);

  const { t } = useTranslation('menu');
  const tabs = [
    { label: t('menu.projects') },
    { label: 'Lorem ipsum' },
    { label: 'Dolor sit amet' },
  ];

  return { tabs, selected, setSelected };
}
