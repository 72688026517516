import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  description__title: {
    marginBottom: theme.spacing(3),
    fontSize: '27pt',
  },
  description__value: {
    fontFamily: 'Trade Gothic LT STD',
    fontSize: '12pt',
  },
  description__container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderLeft: '1px solid',
    borderColor: theme.palette.primary.main,
  },
}));

const Description = ({ description }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.description__container}>
      <Grid item xs={9}>
        <Typography
          color="primary"
          align="justify"
          className={classes.description__value}
          dangerouslySetInnerHTML={{ __html: description.richText }}
        ></Typography>
      </Grid>
    </Grid>
  );
};

export default Description;
