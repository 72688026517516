import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import Slider from '../components/Slider';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: theme.spacing(2),
    '& th, td': {
      border: '1px solid black',
      padding: theme.spacing(1),
    },
    '& td': {
      color: theme.palette.primary.main,
      fontSize: '22px',
      textAlign: 'center',
    },
  },
}));

const SlideshowAndCenterImage = ({
  title,
  textabove,
  text,
  table,
  images,
  ...sliderProps
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Slider {...sliderProps} textCentered />
      <Container className={classes.container}>
        <Box>
          {textabove?.map(({ title, richText }, i) => (
            <Fragment key={i}>
              {title && (
                <Typography variant="h5" className={classes.subtitle}>
                  {title}
                </Typography>
              )}
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </Fragment>
          ))}
        </Box>
      </Container>
      <Box width="100%" display="flex" justifyContent="center">
        {images.map((image, i) => (
          <img
            key={i}
            src={image.src}
            alt={image.alt}
            className={classes.image}
          />
        ))}
      </Box>
      <Container className={classes.container}>
        <Box>
          <table className={classes.table}>
            <thead>
              {table.map(({ title }, i) => (
                <th>
                  <Typography variant="th">{title.toUpperCase()}</Typography>
                </th>
              ))}
            </thead>
            <tbody>
              {table.map(({ body }, i) => (
                <td>
                  <Typography
                    variant="td"
                    dangerouslySetInnerHTML={{ __html: body }}
                  ></Typography>
                </td>
              ))}
            </tbody>
          </table>
        </Box>
        <Box>
          {text.map(({ title, richText }, i) => (
            <Fragment key={i}>
              {title && (
                <Typography variant="h5" className={classes.subtitle}>
                  {title}
                </Typography>
              )}
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </Fragment>
          ))}
        </Box>
      </Container>
    </Fragment>
  );
};

export default SlideshowAndCenterImage;
