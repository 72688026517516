import styles from './TextButtonLineSkin.module.scss';
import Button from '@material-ui/core/Button';
import React from 'react';

export default function TextButtonLineSkin({ data }) {
  
  return (
    <div className={styles.ctaBox}>
      <p>{data.title}</p>
      <Button href={data.link} target="_blank" className={styles.buttonWhite} disableElevation>
        {data.btn}
      </Button>
    </div>
  );
}
