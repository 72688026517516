import styles from './Cta.module.scss';
import React from 'react';
import { Fragment } from 'react';
export default function Cta() {
  return (
    <Fragment>
      <div className={styles.outerBorder}>
        <div className={styles.iconContainer}>
          <div className={styles.upper}>
            <p>&#10095;</p>
          </div>
          <div className={styles.lower}>
            <p>&#10073;</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
