import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    width: '100%',
    background: theme.palette.primary.main,
  },
  container: {
    margin: '0',
    width: '100% !important',
    padding: '0',
    color: theme.palette.tertiary.main,
    [theme.breakpoints.down('lg')]: {
      margin: 'auto',
    },
  },
  grid: {
    justifyContent: 'center',
    width: '100%',
    padding: '1rem 3rem',
  },
  gridSingle: {
    padding: '5px',
  },
  item: {
    padding: '1rem',
    flexBasis: 'unset',
  },
  name: {
    textTransform: 'uppercase',
    fontSize: '8px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  singleName: {
    width: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '8px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  office: {
    fontSize: '8px',
    color: theme.palette.tertiary.main,
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  legal: {
    fontSize: '8px',
    color: theme.palette.tertiary.main,
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    '& > a': {
      color: '#00e3ff',
    },
  },
  table: {
    width: '100%',
    padding: '10px 0',
    tableLayout: 'fixed',

    '& > thead > tr > th': {
      fontWeight: 'normal',
      padding: '4px 1rem',
      color: '#f5f5f5',
      textTransform: 'uppercase',
      textAlign: 'left',
    },
    '& > tbody > tr > td': {
      padding: '0 1rem',
      color: '#f5f5f5',
      textAlign: 'left',
      fontSize: '8px',
      '& > p': {
        marginBottom: '0',
      },
    },
  },
  singleTitle: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  single: {
    fontSize: '8px',
    color: '#f5f5f5',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
  },
}));

function Sitemap({ siteMapData }) {
  const classes = useStyles();

  const { t } = useTranslation('menu');

  const mapSite = siteMapData({ t });
  let singleMapSite = [];
  let siteName = '';
  let siteNames = [];
  if (mapSite.length === 1) {
    siteName = mapSite[0].name;
    const office1 = mapSite[0].office.split('\n')[0];
    const office2 = mapSite[0].office.split('\n')[1];
    if (office2) singleMapSite.unshift(office2.trim());
    if (office1) singleMapSite.unshift(office1.trim());
  } else {
    for (let i = 0; i < mapSite.length; i++) {
      siteNames.push(mapSite[i].name);
    }
  }
  const screenWidth = window.innerWidth;

  return (
    <Box className={classes.topContainer}>
      <Box className={classes.container}>
        <Grid
          container
          className={classes.grid}
          style={{
            justifyContent: 'flex-start',
          }}
        >
          {screenWidth > 800 ?
            (
              <table className={classes.table}>
                <thead>
                  <tr style={{ width: '100%' }}>
                    {siteNames.map((curr, idx) => (
                      <th key={idx}>{curr}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {mapSite.map((curr, idx) => (
                      <td key={idx}>
                        <Typography
                          className={classes.office}
                          dangerouslySetInnerHTML={{ __html: curr.office1 }}
                        ></Typography>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {mapSite.map((curr, idx) => (
                      <td key={idx}>
                        <Typography
                          className={classes.office}
                          dangerouslySetInnerHTML={{ __html: curr.office2 }}
                        ></Typography>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {mapSite.map((curr, idx) => (
                      <td key={idx}>
                        <Typography
                          style={{ marginTop: '10px' }}
                          className={classes.legal}
                          dangerouslySetInnerHTML={{ __html: curr.legal }}
                        ></Typography>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {mapSite.map((curr, idx) => (
                      <td key={idx}>
                        <Typography
                          className={classes.legal}
                          dangerouslySetInnerHTML={{ __html: curr.cap }}
                        ></Typography>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {mapSite.map((curr, idx) => (
                      <td key={idx}>
                        <Typography
                          style={{ marginTop: '10px' }}
                          className={classes.legal}
                          dangerouslySetInnerHTML={{ __html: curr.soc }}
                        ></Typography>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {mapSite.map((curr, idx) => (
                      <td key={idx}>
                        <Typography
                          style={{ marginTop: '10px' }}
                          className={classes.legal}
                          dangerouslySetInnerHTML={{ __html: curr.contacts }}
                        ></Typography>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            ) :
            // less than 800px width
          (
            (mapSite.map((society, idx) => (
              <table className={classes.table} sm={12}>
                <thead>
                  <tr style={{ width: '100%' }}>
                    <th>{society.name}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td key={idx}>
                      <Typography
                        className={classes.office}
                        dangerouslySetInnerHTML={{ __html: society.office1 }}
                      ></Typography>
                    </td>
                  </tr>
                  <tr>
                    <td key={idx}>
                      <Typography
                        className={classes.office}
                        dangerouslySetInnerHTML={{ __html: society.office2 }}
                      ></Typography>
                    </td>
                  </tr>
                  {/* <tr>
                    <td key={idx}>
                      <Typography
                        style={{ marginTop: '10px' }}
                        className={classes.legal}
                        dangerouslySetInnerHTML={{ __html: society.register }}
                      ></Typography>
                    </td>
                  </tr> */}
                  <tr>
                    <td key={idx}>
                      <Typography
                        style={{ marginTop: '10px' }}
                        className={classes.legal}
                        dangerouslySetInnerHTML={{ __html: society.legal }}
                      ></Typography>
                    </td>
                  </tr>
                  <tr>
                    <td key={idx}>
                      <Typography
                        className={classes.legal}
                        dangerouslySetInnerHTML={{ __html: society.cap }}
                      ></Typography>
                    </td>
                  </tr>
                  <tr>
                    <td key={idx}>
                      <Typography
                        className={classes.legal}
                        dangerouslySetInnerHTML={{ __html: society.soc }}
                      ></Typography>
                    </td>
                  </tr>
                  <tr>
                    <td key={idx}>
                      <Typography
                        style={{ marginTop: '10px' }}
                        className={classes.legal}
                        dangerouslySetInnerHTML={{ __html: society.contacts }}
                      ></Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          )
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default Sitemap;
