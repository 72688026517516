import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '10rem',
  },
  text: {
    fontSize: '1.5rem',
    color: theme.palette.error.main,
    whiteSpace: 'pre',
    textAlign: 'center',
  },
}));

const Error = ({ message }) => {
  const { t } = useTranslation('common');
  const text = message || t('app.error');

  const classes = useStyles();

  return (
    <Box
      style={{ height: '300px' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <ErrorIcon color="error" className={classes.icon} />
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
};

export default Error;
