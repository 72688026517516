import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  detail__category: {
    fontFamily: 'Druk Wide Medium',
    fontSize: '12pt',
    color: 'black',
    textTransform: 'uppercase',
    '& > p' : {
      marginTop:'0px!important',
    }
  },
  detail__title: {
    fontFamily: 'Druk Wide Medium',
    fontWeight: 'bold',
    fontSize: '9.5pt',
    color: 'black',
    textTransform: 'uppercase',
  },
  detail__value: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '12pt',
    textTransform: 'uppercase',
  },
  detailsContainer: {
    padding: '0 2rem',
  },
}));

const Details = ({ details, category }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailsContainer}>
      <Box ml="2rem" mb="2rem">
        <Typography
          className={classes.detail__category}
          dangerouslySetInnerHTML={{ __html: category }}
        ></Typography>
      </Box>
      {details?.map((detail, key) => (
        <Box ml="2rem" mb="2rem" key={key}>
          <Typography
            className={classes.detail__title}
            dangerouslySetInnerHTML={{ __html: detail.title }}
          ></Typography>
          <Typography
            className={classes.detail__value}
            variant="h4"
            color="primary"
            dangerouslySetInnerHTML={{ __html: detail.value }}
          ></Typography>
        </Box>
      ))}
    </div>
  );
};

export default Details;
