import styles from './MapPointer.module.scss';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';

export default function MapPointer({ data }) {

  const elaboratedCoords = {
    x: 'calc('+data.x+'px - 13px)',
    y: 'calc('+data.y+'px - 13px)',
  };

  let labelElaboratedCoords = {}

  if (data.position == 'top') {
    labelElaboratedCoords = {
      x: 'calc('+data.x+'px - 13px)',
      y: 'calc('+data.y+'px + 19px)'
    }
  }
  if (data.position == 'bottom') {
    labelElaboratedCoords = {
      x: 'calc('+data.x+'px - 13px)',
      y: 'calc('+data.y+'px - 35px)'
    }
  }
  if (data.position == 'right') {
    labelElaboratedCoords = {
      x: 'calc('+data.x+'px + 19px)',
      y: 'calc('+data.y+'px - 7px)'
    }
  }

  return (
    <span>
      {data.hq == false && (
        <div className={styles.pointer} style={{left: elaboratedCoords.x, bottom: elaboratedCoords.y, width: '30px', height: '30px'}}>
          <div className={styles.circle}></div>
        </div>
      )}
      {data.hq == true && (
        <div className={styles.pointer} style={{left: elaboratedCoords.x, bottom: elaboratedCoords.y, width: '31px', height: '31px' }}>
          <HomeIcon className={styles.home}></HomeIcon>
        </div>
      )}
      <div className={styles.label} style={{left: labelElaboratedCoords.x, bottom: labelElaboratedCoords.y }}>{data.location.toUpperCase()}</div>
    </span>
  );
}
