import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Grid, makeStyles } from '@material-ui/core';

import { BasePage, Entity } from 'shared';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '1180px',
  },
  textContainer: {
    padding: '0 4rem',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  subtitle: {
    textTransform: 'uppercase',
    color: '#003a78',
    fontSize: '22pt',
    marginBottom: '20px',
    marginTop: '20px',
    fontFamily: 'Druk Wide Medium',
    fontWeight: 'normal',
    lineHeight: '1.2',
    '@media (max-width: 900px)': {
      fontSize: '25px',
    },
    '@media (max-width: 700px)': {
      fontSize: '22px',
    },
    '@media (max-width: 600px)': {
      fontSize: '20px',
    },
    '@media (max-width: 600px)': {
      fontSize: '18px',
    },
  },
  vl: {
    margin: 'auto',
    width: '2px',
    height: '40px',
    backgroundColor: '#000',
  },
  entityContainer: {
    padding: '0 4rem',
    marginTop: '50px',
    display: 'grid',
    placeItems: 'center',
    gap: '50px',
    marginBottom: theme.spacing(4),
    '@media screen and (max-width: 1200px)': {
      gridTemplateColumns: '1fr 1fr!important',
    },
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: '1fr!important',
    },
    '@media (max-width: 900px)': {
      padding: '0 2rem!important',
    },
  },
}));

const ManagementPage = ({ text, boxes }) => {
  const classes = useStyles();
  const gridTemplateColumns =
    boxes.length > 3 ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr';

  return (
    <BasePage>
      <Container className={classes.container}>
        <div className={classes.textContainer}>
          {text.map(({ title, richText }, i) => (
            <div key={i}>
              <Typography
                className={classes.subtitle}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: title }}
              ></Typography>
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </div>
          ))}
        </div>
        {boxes && (
          <div
            className={classes.entityContainer}
            style={{ gridTemplateColumns: gridTemplateColumns }}
          >
            {boxes.map((el) => (
              <Grid>
                <Entity props={el}></Entity>
              </Grid>
            ))}
          </div>
        )}
      </Container>
    </BasePage>
  );
};

export default ManagementPage;
