import React from 'react';
import { Helmet as HelmetAsync } from 'react-helmet-async';

const Helmet = ({ pageTitle }) => {
  return (
    <HelmetAsync>
      <title>{pageTitle}</title>
    </HelmetAsync>
  );
};

export default Helmet;
