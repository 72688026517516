import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    fontSize: '18pt',
    width: '500px',
    border: '1px solid ' + theme.palette.primary.main,
    borderRadius: '0px',
    color: theme.palette.primary.main,
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  title: {
    width: '100%',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingRight: '10px',
    fontSize: '13pt',
    textTransform: 'uppercase',
    '@media (max-width:600px)': {
      fontSize: '10pt',
    },
  },
  subTitle: {
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '12pt',
    '& > ul': {
      paddingLeft: '16px',
    },
  },
  properties: {
    padding: '10px',
    fontSize: '7pt',
    color: '#000',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  topSection: {
    height: '250px',
    borderBottom: '1px solid #000',
  },
  logoContainer: {
    position: 'relative',
    bottom: '240px',
    left: '8px',
    width: 'calc(100% - 20px)',
  },
  logo: {
    maxHeight: '40px',
  },
  goButton: {
    position: 'relative',
    bottom: '190px',
    left: '385px',
    width: '100px',
    height: '30px',
    backgroundColor: '#003a78',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#014996',
    },
  },
}));

const EntityTopImage = (props) => {
  const { title, properties, image, icon, link } = props.props;

  const {
    i18n: { language },
  } = useTranslation();

  const classes = useStyles();
  return (
    <a href={link} target="_blank" style={{ textDecoration: 'none' }}>
      <div className={classes.container}>
        <Grid item xs={12} className={classes.topSection}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={image}
              alt="img"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>

          {icon && (
            <div className={classes.logoContainer}>
              <img src={icon} alt="img" className={classes.logo} />
            </div>
          )}
        </Grid>
        <Grid container xs={12}>
          <div
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <div
            className={classes.subTitle}
            dangerouslySetInnerHTML={{ __html: properties }}
          ></div>
        </Grid>
      </div>
    </a>
  );
};

export default EntityTopImage;
