import styles from './DownloadLineSkin.module.scss';
import React from 'react';

function DownloadIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      width="40px"
      height="40px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#003a78"
        stroke="none"
      >
        <path
          d="M2500 4309 c-29 -12 -67 -46 -83 -74 -9 -15 -13 -322 -17 -1159 l-5
            -1139 -277 221 c-290 231 -308 242 -383 228 -65 -12 -125 -85 -125 -150 0 -14
            7 -44 16 -66 14 -34 74 -86 429 -370 227 -181 430 -337 451 -346 83 -35 81
            -36 553 341 236 189 437 355 446 369 58 88 10 206 -95 237 -70 21 -97 6 -399
            -236 -154 -124 -283 -225 -286 -225 -2 0 -6 513 -7 1139 -3 1133 -3 1140 -24
            1167 -11 15 -32 37 -46 47 -31 23 -111 32 -148 16z"
        />
        <path
          d="M735 2066 c-37 -17 -70 -52 -84 -89 -7 -18 -11 -158 -11 -391 0 -293
              3 -373 15 -421 45 -172 179 -305 352 -350 86 -23 3020 -23 3106 0 178 46 320
              193 357 368 7 35 10 181 8 425 -3 356 -4 372 -24 398 -39 53 -71 69 -134 69
              -63 0 -95 -16 -134 -69 -20 -26 -21 -43 -26 -406 -5 -363 -6 -380 -26 -406
              -11 -15 -33 -37 -48 -48 -27 -21 -28 -21 -1526 -21 -1498 0 -1499 0 -1526 21
              -15 11 -37 33 -48 48 -20 26 -21 43 -26 406 -5 363 -6 380 -26 406 -11 15 -32
              37 -46 47 -34 25 -113 32 -153 13z"
        />
      </g>
    </svg>
  );
}

function DownloadIconArrow() {
  return (
    <svg
      fill="#003a78"
      stroke="none"
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      width="25"
      height="25"
      viewBox="0 0 450 250"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
      />
    </svg>
  );
}

export default function DownloadLineSkin({ data }) {
  return (
    <div className={styles.row}>
      <p className={styles.documentTitle}>{data.title}</p>
      <a href={data.link} target="_blank" variant="body1" rel="noreferrer">
        <div class="svg-container">
          <DownloadIconArrow />
        </div>
      </a>
    </div>
  );
}
