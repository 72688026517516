import React, { Fragment } from 'react';
// import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import Table from '../components/Table';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    margin: 'auto',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    textTransform: 'uppercase',
    color: '#003a78',
    fontSize: '24pt',
    marginBottom: '20px',
    fontFamily: 'Druk Wide Medium',
    fontWeight: 'normal',
    lineHeight: '1.2',
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const TopImageAndDocuments = ({ title, text, documents, images }) => {
  const classes = useStyles();
  // const { t } = useTranslation();

  const columns = ['title'];
  const rows = documents.map((record) => [record.title]);
  const actions = documents.map((record) => record.link);

  return (
    <Fragment>
      {/* <Box width="100%" display="flex" justifyContent="center">
        {images.map((image, i) => (
          <img key={i} src={image.src} alt={image.alt} />
        ))}
      </Box> */}
      <Container className={classes.container}>
        <Box>
          {/* <Typography variant="h2" className={classes.title}>
            {title}
          </Typography> */}
          {text.map(({ title, richText }, i) => (
            <Fragment key={i}>
              {title && (
                <Typography variant="h5" className={classes.subtitle}>
                  {title}
                </Typography>
              )}
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </Fragment>
          ))}
        </Box>
        {rows && (
          <Table
            rows={rows}
            columns={columns}
            actions={actions}
            // title={t('pages:documents.table.title')}
            className={classes.table}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default TopImageAndDocuments;
