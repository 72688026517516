import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { DownloadLineSkin } from 'shared';
import Box from '@material-ui/core/Box';
import TextButtonLineSkin from '../components/TextButtonLineSkin/TextButtonLineSkin';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: '4rem',
    textTransform: 'uppercase',
  },
  table: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  image: {
    height: '200px',
  },
  grid: {
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
    rowGap: '40px',
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
  },
  boxContainer: {
    marginBottom: theme.spacing(8),
    gap: '10px',
    maxWidth: '1180px',
    margin: 'auto',
    marginBottom: '64px',
    padding:'0 2rem'
  },
  boxBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '270px',
    border: '1px solid #003a78',
    padding: '1rem',
    margin: '0 20px',
    height: '270px',
  },
  boxTitle: {
    color: '#003a78',
  },
  cta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const SuppliersPage = ({
  title,
  text,
  documents,
  sections,
  images,
  ctas,
  boxes,
}) => {
  const classes = useStyles();

  return (
    <div>
      {/* <TitleAndFixedImage src={images.} title={title}></TitleAndFixedImage> */}
      <Container className={classes.container}>
        <Typography
          className={classes.textBody}
          variant="body1"
          dangerouslySetInnerHTML={{ __html: text[0].richText }}
        ></Typography>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          className={classes.image}
        >
          {images.map((image, i) => (
            <img key={i} src={image.src} alt={image.alt} />
          ))}
        </Box>
        {sections.map((section, i) => (
          <div className={classes.rowContainer}>
            {section.elements.buttons.map((doc, i) => (
              <TextButtonLineSkin
                key={JSON.stringify(doc)}
                data={doc}
              ></TextButtonLineSkin>
            ))}
          </div>
        ))}
      </Container>
      <div className={classes.boxContainer}>
        {boxes.map((doc, i) => (
          <DownloadLineSkin key={JSON.stringify(doc)} data={doc}></DownloadLineSkin>
        ))}
      </div>
    </div>
  );
};

export default SuppliersPage;
