import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { Box, Container, Grid, Hidden, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import LanguageSelector from './LanguageSelector/LanguageSelector';
import Menu from './Menu/Menu';
import theme from '../../theme';

const useStyles = makeStyles((theme) => {
  return {
    top: {
      padding: 0,
    },
    logo: {
      cursor: 'pointer',
    },
    menu: {
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: '100',
      display: 'block',
      borderTop: '0.5px solid ' + theme.palette.secondary.main,
      borderBottom: '4px solid ' + theme.palette.primary.main,
      backgroundColor: theme.palette.tertiary.main,
    },
    social: {
      '& div': {
        opacity: '.5',
        '&:hover': {
          opacity: 1,
        },
      },
    },
  };
});

const HeaderDesktop = ({ logo, logoSmall, menuData }) => {
  const classes = useStyles();
  const trigger = useScrollTrigger();

  // WORKAROUND: Remove this piece of code when the site is ready
  const location = useLocation();
  const path = location.pathname;

  const isPffLogo = logo.includes('pff');

  return (
    <Hidden lgDown>
      <Slide in={!trigger}>
        <Box
          width="calc(100% - 1rem)"
          maxWidth="1310px"
          mt="1rem"
          mx="auto"
          pt="20px"
          pb="6px"
        >
          <Container className={classes.top} maxWidth="xl">
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <NavLink exact to="/">
                  <img
                    src={logo}
                    alt="Company logo"
                    // width={544}
                    className={classes.logo}
                    height={isPffLogo ? 100 : 34}
                  ></img>
                </NavLink>
              </Grid>
              {path !== '/_pages' && (
                <Grid item>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <LanguageSelector />
                    </Grid>
                    <Grid item>
                      <Link
                        target="_blank"
                        to={{
                          pathname:
                            'https://www.linkedin.com/company/fincantieri-infrastructure/',
                        }}
                        className={classes.social}
                      >
                        <div id="linkedin-icon" />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      </Slide>
      {path !== '/_pages' && (
        <Box className={classes.menu} style={{borderBottomColor: !isPffLogo ? theme.palette.primary.main : '#f5f5f5'}}>
          <Menu menuData={menuData} logoSmall={logoSmall} />
        </Box>
      )}
    </Hidden>
  );
};

export default HeaderDesktop;
