import React, { Fragment, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import Slider from '../../components/Slider';
import PhotoGalleryModal from './PhotoGalleryModal';

// import BoxList from './BoxList';
import Description from './Description';
import Details from './Details';
import Numbers from './Numbers';
import TitleWithFixedImage from '../../components/Hero/TitleAndFixedImage/TitleAndFixedImage';
import ProjectsSlider from '../../components/ProjectsSlider/ProjectsSlider';
import TextButtonLineSkin from '../../components/TextButtonLineSkin/TextButtonLineSkin';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: '1480px',
    paddingLeft: '12px !important',
    paddingRight: '12px !important',
  },
  ctaContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: '1180px',
    padding: '0 4rem !important',
    margin: '100px auto',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  title_section: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '22pt',
    marginBottom: '60px',
    textTransform: 'uppercase',
  },
  grid: {
    display: 'flex',
    margin: '100px auto',
    width: 'fit-content',
    columnGap: '100px',
    rowGap: '100px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '30px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  detailsGrid: {
    width: '100%',
    margin: '100px auto',
  },

  heroContainer: {
    position: 'relative',
  },
}));

const Case = ({
  title,
  text,
  numbers,
  details,
  fixed_image,
  video,
  cta_data,
  datalist,
  ...sliderProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const firstThreeElements = numbers.filter((el, i) => i <= 2);
  const secondTwoElements = numbers.filter((el, i) => i > 2);

  const {
    i18n: { language },
  } = useTranslation();

  let discoverTitle = {
    it: 'Scopri tutti i progetti',
    en: 'Discover all projects',
  };

  const actualURL = window.location.pathname.slice(1);
  const currentProject = datalist.find((project) => project.link === actualURL);

  
  if(currentProject){
    var category = '';
    for (var i = 0; i < currentProject.category.length; i++) {
      category += '<p>' + currentProject.category[i] + '</p>';
    }
  } else {
    window.location.pathname = '/projects/';
    return;
  }


  const others_projectsData = datalist.filter(
    (project) => project.link !== actualURL,
  );

  return (
    <Fragment>
      <div className={classes.heroContainer}>
        <TitleWithFixedImage
          src={fixed_image[0]}
          innerHtml={fixed_image[1]}
          srcMob={fixed_image[2]}
          hasSign={fixed_image[3]}
        ></TitleWithFixedImage>
      </div>
      <Container className={classes.container}>
        <Grid container>
          <Grid container justify="center" className={classes.detailsGrid}>
            <Grid item xs={12} md={5} lg={5}>
              {!!details?.length && (
                <Grid>
                  <Details details={details} category={category} />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={7} lg={7}>
              {text.map((description, i) => (
                <Grid key={i}>
                  <Description description={description} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                className={classes.title_section}
              >
                project key numbers
              </Typography>
              <div className={classes.grid}>
                <Numbers numbers={firstThreeElements} />
              </div>
              <div className={classes.grid}>
                <Numbers numbers={secondTwoElements} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <PhotoGalleryModal
        {...sliderProps}
        handleClose={handleClose}
        open={open}
      />
      {JSON.stringify(...sliderProps)}
      {sliderProps.imageData.length > 1 && (
        <Slider
          {...sliderProps}
          textCentered
          isProjects={true}
          handleOpen={handleOpen}
        />
      )}

      <Container
        style={{
          paddingTop: sliderProps.imageData.length > 1 ? '100px' : '60px',
        }}
      >
        <Typography
          variant="h3"
          align="center"
          className={classes.title_section}
        >
          {discoverTitle[language]}
        </Typography>
      </Container>
      <ProjectsSlider projectsData={others_projectsData}></ProjectsSlider>
    </Fragment>
  );
};

export default Case;
