import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiBox from '@material-ui/core/Box';
import { useAnimationOnHover } from './useAnimationOnHover';
import ForwardIcon from '@material-ui/icons/Forward';

const useStyles = makeStyles((theme) => {
  return {
    box__overlay: {
      visibility: 'visible',
      background: (props) =>
        props.imageBox &&
        `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.tertiary.main} 75%, ${theme.palette.tertiary.main} 100%)`,
      backgroundColor: (props) =>
        props.imageBox ? theme.palette.secondary.dark : props.backgroundColor,
      mixBlendMode: 'multiply',
      position: 'absolute',
      top: '0',
      height: '100%',
      width: (props) => (props.widthFixed ? props.width : '100%'),
      opacity: '0.75',
    },
    box__image: {
      backgroundImage: (props) => props.imageBox && `url("${props.imageBox}")`,
      backgroundSize: (props) => props.imageBox && 'cover',
      backgroundRepeat: (props) => props.imageBox && 'no-repeat',
      backgroundPosition: (props) => props.imageBox && 'center center',
      height: '100%',
      width: (props) => (props.widthFixed ? props.width : '100%'),
      position: 'relative',
    },
    box__inner__title: {
      marginTop: '25px',
       '& p': {
        width : 'fit-content',
        fontFamily: 'Druk Wide Medium',
        fontSize: '14pt',
        textTransform: 'uppercase',
        paddingRight:'5px',
        paddingLeft : '16px',
        backgroundColor: theme.palette.primary.main,
        display : 'inline',
        fontWeight: 'normal',
        whiteSpace:'nowrap',
        color: (props) => props.colorTitle || theme.palette.tertiary.main,
        "@media (max-width: 900px)" :{
          fontSize: '14pt',
        },
        "@media (max-width: 600px)" :{
          fontSize: '12pt',
        },
      }

    },
    box__inner__title_noimg: {
      fontFamily: 'Druk Wide Medium',
      fontSize: '14pt',
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontWeight: (props) => (props.bold ? 'bold' : 'normal'),
    },
    box__inner__img: {
      maxWidth: '100%',
      maxHeight: '60px',
      visibility: 'visible',
    },
    box__inner__cta: {
      fontSize: '12pt',
      padding: '8px 16px'
    },
    box__inner__cta_box: {
      alignSelf: 'flex-end',
      marginBottom : '20px',
    },
    box__inner__ctanoimg: {
      fontSize: '12pt',
      color: theme.palette.primary.main,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '0px',
    },
    box: {
      overflow: 'hidden',
      width: (props) => (props.widthFixed ? props.width : '100%'),
      aspectRatio: (props) => (props.aspectRatio ? props.aspectRatio : '1/1'),
      cursor: 'pointer',
    },
    box__inner: {
      position: 'relative',
      zIndex: 1,
      height: '100%',
      width: (props) => (props.widthFixed ? props.width : '100%'),
    },
    box__innernoimg: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      position: 'relative',
      zIndex: 1,
      height: '100%',
      width: (props) => (props.widthFixed ? props.width : '100%'),
    },
  };
});

const Box = (props) => {
  const { hovered, handleMouseLeave, handleMouseOver, animationClass } =
    useAnimationOnHover();

  const {
    title,
    imageTitle,
    colorTitle,
    colorText,
    backgroundColor = 'black',
    heightFixed = true,
    widthFixed = false,
    imageBox = null,
    variantTitle = 'div',
    fontweight = true,
    bold = true,
    width = '425px',
    height = '425px',
    cta,
    hasCta,
    aspectRatio,
  } = props;

  const classes = useStyles({
    hovered,
    colorTitle,
    backgroundColor,
    imageBox,
    heightFixed,
    widthFixed,
    colorText,
    fontweight,
    bold,
    width,
    height,
    aspectRatio,
  });

  const history = useHistory();
  const handleClick = () => {
    const url = cta.url;
    if (url.includes('http')) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };

  return imageBox ? (
    <div
      className={classes.box}
      style={props.style}
      onClick={cta ? handleClick : undefined}
    >
      <div className={classes.box__image}>
        <MuiBox
          display="flex"
          justifyContent={title || imageTitle ? 'space-between' : 'flex-end'}
          flexDirection="column"
          alignItems="flex-start"
          className={classes.box__inner}
        >
          {title && (
            <Typography
              className={classes.box__inner__title}
              variant={variantTitle}
              dangerouslySetInnerHTML={{ __html: title }}
            >
            </Typography>
          )}
          {imageTitle && (
            <img
              className={classes.box__inner__image}
              src={imageTitle}
              alt="Company logo"
            />
          )}
          {cta && hasCta && (
            <div
              className={[classes.box__inner__cta_box, animationClass].join(
                ' ',
              )}
            >
              <Button
                className={classes.box__inner__cta}
                variant="contained"
                onClick={handleClick}
                disableElevation
              >
                {cta.text}
              </Button>
            </div>
          )}
        </MuiBox>
      </div>
    </div>
  ) : (
    <div
      className={classes.box}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      style={props.style}
      onClick={cta ? handleClick : undefined}
    >
      <div className={classes.box__image}>
        <MuiBox
          p="1rem"
          display="flex"
          justifyContent={title || imageTitle ? 'space-between' : 'flex-end'}
          flexDirection="column"
          alignItems="flex-start"
          className={classes.box__innernoimg}
        >
          {title && (
            <Typography
              className={classes.box__inner__title_noimg}
              variant={variantTitle}
            >
              {title}
            </Typography>
          )}
          {imageTitle && (
            <img
              className={classes.box__inner__image}
              src={imageTitle}
              alt="Company logo"
            />
          )}
          {cta && (
            <div
              className={[classes.box__inner__cta_box, animationClass].join(
                ' ',
              )}
            >
              <Button
                className={classes.box__inner__ctanoimg}
                onClick={handleClick}
                disableElevation
              >
                <ForwardIcon />
              </Button>
            </div>
          )}
        </MuiBox>
      </div>
    </div>
  );
};

Box.propTypes = {
  title: PropTypes.string,
  colorTitle: PropTypes.string,
  colorText: PropTypes.string,
  backgroundColor: PropTypes.string,
  heightFixed: PropTypes.bool,
  widthFixed: PropTypes.bool,
  textAlign: PropTypes.string,
  imageBox: PropTypes.string,
};

export default Box;
