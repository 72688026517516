import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import PhotoGallery from './PhotoGallery';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.75) !important',
    },
    '& .MuiPaper-root': {
      background: 'transparent',
      position: 'relative',
    },
    '& .MuiIconButton-root': {
      color: theme.palette.tertiary.main,
      '&:hover': {
        // backgroundColor: theme.palette.tertiary.light,
        background: 'none !important',
        color: theme.palette.tertiary.light,
      },
    },
  },
  dialogTitle: {
    position: 'absolute',
    right: 0,
    zIndex: 10,
  },
  dialogContent: {
    overflow: 'hidden !important',
    padding: 0,
    width: '100%',
    height: '100vh',
  },
}));

const PhotoGalleryModal = ({ handleClose, open, ...sliderProps }) => {
  const classes = useStyles();

  return (
    <Dialog
      elevation={0}
      onClose={handleClose}
      open={open}
      className={classes.dialogRoot}
      fullScreen
    >
      <DialogTitle onClose={handleClose} className={classes.dialogTitle}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <PhotoGallery {...sliderProps} />
      </DialogContent>
    </Dialog>
  );
};

export default PhotoGalleryModal;
