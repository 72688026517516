import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  detail__title: {
    fontFamily: 'Druk Wide Medium',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  detail__value: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontFamily: 'Druk Wide Medium',
    fontWeight: 500,
    fontSize: '40px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  detail__unita: {
    fontFamily: 'Druk Wide Medium',
    fontWeight: 'bold',
    fontSize: '25px',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    position: 'absolute',
    bottom: '-30px',
    right: '1px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  numbercontainer: {
    position: 'relative',
    margin: 'auto',
  },
}));

const Numbers = ({ numbers }) => {
  const classes = useStyles();

  return (
    <>
      {numbers.map((number, key) => (
        <Grid className={classes.numbercontainer}>
          <Typography
            className={classes.detail__value}
            variant="h3"
            color="primary"
          >
            {number.value.map((value, i) => (
              <Fragment key={i}>
                {' '}
                <Typography
                  className={classes.detail__value}
                  dangerouslySetInnerHTML={{ __html: value }}
                ></Typography>
              </Fragment>
            ))}
          </Typography>
          <Typography
            className={classes.detail__title}
            align="left"
            dangerouslySetInnerHTML={{
              __html:
                (number.unita != undefined ? number.unita : '') +
                ' ' +
                number.title,
            }}
          ></Typography>
        </Grid>
      ))}
    </>
  );
};

export default Numbers;
