import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { TitleWithLine, DownloadLineSkin, TextButtonLineSkin } from 'shared';

import Table from '../components/Table';
import CTA from '../components/CallToAction';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '14pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    maxWidth: '100%',
  },
}));

const TopImageAndLinks = ({ title, text, ctas, images, sections, documents }) => {
  const classes = useStyles();

  const columns = ['title'];
  const rows = documents.map((record) => [record.title]);
  const actions = documents.map((record) => record.link);

  const description = text[0]?.richText;
  text.shift(1);

  return (
    <Fragment>
      <Box width="100%" display="flex" justifyContent="center">
        {images.map((image, i) => (
          <img
            key={i}
            src={image.src}
            alt={image.alt}
            className={classes.image}
          />
        ))}
      </Box>
      <Container className={classes.container}>

        <Typography
          className={classes.textBody}
          variant="body1"
          dangerouslySetInnerHTML={{ __html: description }}
        ></Typography>

        <Grid container spacing={2} style={{marginBottom: '20px'}}>
          {text.map(({ title, richText }, i) => (
            <Fragment key={i}>
              <Grid item xs={6} md={6} className={classes.section}>
                {richText && title && (
                  <Typography variant="h5" className={classes.subtitle}>
                    {title.toUpperCase()}
                  </Typography>
                )}
                {richText && title && (
                  <Typography
                    className={classes.textBody}
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: richText }}
                  ></Typography>
                )}
              </Grid>
            </Fragment>
          ))}
        </Grid>

        {ctas.map((cta, i) => (
          <CTA key={i} title={cta.title} link={cta.link} text={cta.text} />
        ))}

        {sections &&
          sections.map((section, i) => (
            <div className={classes.rowContainer}>
              {section.title && 
                <TitleWithLine
                key={JSON.stringify(section)}
                title={section.title}
                ></TitleWithLine>
              }
              {section.elements.buttons &&
              section.elements.buttons.map((doc, i) => (
                <TextButtonLineSkin
                  key={JSON.stringify(doc)}
                  data={doc}
                ></TextButtonLineSkin>
              ))}
              {section.elements.documents.map((doc, i) => (
                <DownloadLineSkin
                  key={JSON.stringify(doc)}
                  data={doc}
                ></DownloadLineSkin>
              ))}
            </div>
          ))}

        <Table
          rows={rows}
          columns={columns}
          actions={actions}
          className={classes.table}
        />
      </Container>
    </Fragment>
  );
};

export default TopImageAndLinks;
