import React from 'react';

import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    borderBottom: '0.2pt solid ' + theme.palette.secondary.main,
    width: '100%',
    '& .MuiTabs-indicator': {
      borderLeft: '8px solid ' + theme.palette.secondary.dark,
      borderRight: '8px solid ' + theme.palette.secondary.dark,
    },
    minHeight: '48px',
    position: 'relative',
  },
  tabs: {
    position: 'absolute',
    bottom: '-1px',
    '& .MuiTabs-flexContainer': {
      gap: '1rem',
    },
  },
  tab: {
    borderTop: '0.2pt solid ' + theme.palette.secondary.main,
    borderLeft: '0.2pt solid ' + theme.palette.secondary.main,
    borderRight: '0.2pt solid ' + theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.main,
  },
}));

const Tabs = ({ selected, setSelected, tabs }) => {
  const classes = useStyles();

  const handleChange = (_, tab) => {
    setSelected(tab);
  };

  return (
    <Box className={classes.tabsWrapper} display="flex" justifyContent="center">
      <MuiTabs
        indicatorColor="primary"
        textColor="primary"
        value={selected}
        onChange={handleChange}
        className={classes.tabs}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tab, i) => (
          <Tab key={i} value={i} label={tab.label} className={classes.tab} />
        ))}
      </MuiTabs>
    </Box>
  );
};

export default Tabs;
