import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import Helmet from './Helmet';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '10rem',
    color: theme.palette.warning.main,
  },
  text: {
    fontSize: '1.5rem',
    color: theme.palette.warning.main,
  },
}));

function NoMatch({ messageText }) {
  let location = useLocation();

  const { t } = useTranslation();
  const message = (
    <Fragment>
      {t('common:app.no_match')} <code>{location.pathname}</code>
    </Fragment>
  );

  const classes = useStyles();

  return (
    <Fragment>
      <Helmet
        pageTitle={`${t('common:app.name')} | ${t('common:app.no_match')}`}
      />
      <Box
        flex="1"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <WarningIcon className={classes.icon} />
        <Typography className={classes.text}>
          {messageText || message}
        </Typography>
        <Button variant="contained" href="/" disableElevation>
          {t('common:app.go_back_home')}
        </Button>
      </Box>
    </Fragment>
  );
}

export default NoMatch;
