const getPageContent = (data) => {
  const text = data
    .filter((record) => record.key === 'text')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const textabove = data
    .filter((record) => record.key === 'textabove')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const twocolumns = data
    .filter((record) => record.key === 'twocolumns')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const details = data
    .filter((record) => record.key === 'details')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const numbers = data
    .filter((record) => record.key === 'numbers')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const imageData = data
    .filter((record) => record.key === 'slider')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const documents = data
    .filter((record) => record.key === 'document')
    .reduce((acc, record) => [...acc, ...record.elements], []);
  const buttons = data
    .filter((record) => record.key === 'button')
    .reduce((acc, record) => [...acc, ...record.elements], []);
  const accordions = data
    .filter((record) => record.key === 'accordion')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const boxes = data
    .filter((record) => record.key === 'box')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const staticBoxes = data
    .filter((record) => record.key === 'static-box')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const ctas = data
    .filter((record) => record.key === 'cta')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const images = data
    .filter((record) => record.key === 'image')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const cards = data
    .filter((record) => record.key === 'card')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const quotes = data
    .filter((record) => record.key === 'quote')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const table = data
    .filter((record) => record.key === 'table')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const mezzi_table = data
    .filter((record) => record.key === 'mezzi_table')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const fixed_image = data
    .filter((record) => record.key === 'fixed_image')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const video = data
    .filter((record) => record.key === 'video')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const cta_data = data
    .filter((record) => record.key === 'cta_data')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const projects_data = data
    .filter((record) => record.key === 'projects_data')
    .reduce((acc, record) => [...acc, ...record.elements], []);

  const sections = data
    .filter((record) => record.key === 'section')
    .map((section) => ({
      title: section.title,
      elements: getPageContent(section.elements),
    }));

  return {
    text,
    textabove,
    twocolumns,
    numbers,
    details,
    imageData,
    documents,
    buttons,
    boxes,
    ctas,
    accordions,
    sections,
    images,
    cards,
    staticBoxes,
    quotes,
    table,
    mezzi_table,
    fixed_image,
    video,
    projects_data,
    cta_data
  };
};

export default getPageContent;
