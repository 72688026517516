import React, { Fragment } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MuiBox from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import Box from '../components/Box';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    width: '100%',
    maxWidth: '1180px',
    padding: '0 4rem !important',
    '@media (max-width:981px)':{
      padding: '0 2rem !important',
    }
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: '12pt',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  textBody: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '&:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  placeholder: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xl')]: {
      width: '425px',
      height: '425px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: '35%',
    },
    width: '100%',
    aspectRatio: '1/1',
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const TextAndBoxes = ({ title, text, boxes }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Container className={classes.container}>
        <MuiBox>
          {/* <Typography variant="h2" className={classes.title}>
            {title}
          </Typography> */}
          {text.map(({ title, richText }, i) => (
            <Fragment key={i}>
              {title && (
                <Typography variant="h5" className={classes.subtitle}>
                  {title}
                </Typography>
              )}
              <Typography
                className={classes.textBody}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: richText }}
              ></Typography>
            </Fragment>
          ))}
        </MuiBox>
        <Grid
          container
          justify="center"
          direction="row"
          wrap="wrap"
          spacing={3}
          className={classes.grid}
        >
          {boxes.map((box, i) => {
            const { imageTitle, image, cta } = box;
            return (
              <Grid item xs={12} sm={6} md={12 / boxes.length} key={i}>
                <Box imageTitle={imageTitle} imageBox={image} cta={cta} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default TextAndBoxes;
