import React, { useState } from 'react';
import ProjectCard from '../ProjectCard/ProjectCard';
import styles from './ProjectsSlider.module.scss'; // Assuming you have CSS module for styling

export default function ProjectsSlider({ projectsData }) {
  const [startIndex, setStartIndex] = useState(0);
  const endIndex = Math.min(startIndex + 3, projectsData.length);

  const nextSlides = () => {
    setStartIndex((prevIndex) =>
      Math.min(prevIndex + 1, projectsData.length - 3),
    );
  };

  const prevSlides = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.projectGrid}>
        {projectsData.slice(startIndex, endIndex).map((project, index) => (
          <ProjectCard project={project} forSliderFlag={true}/>
        ))}
      </div>
      <button
        className={styles.prevButton}
        onClick={prevSlides}
        disabled={startIndex === 0}
      >
        &#8249;
      </button>
      <button
        className={styles.nextButton}
        onClick={nextSlides}
        disabled={endIndex === projectsData.length}
      >
        &#8250;
      </button>
    </div>
  );
}
