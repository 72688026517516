import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  slider__bottom: {
    zIndex: '20',
    height: '50px',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 30px 0px 25px',
    alignItems: 'center',
    backgroundColor: 'transparent',
    bottom: '0',
    transform: 'translate(-50%, 0%)',
    left: '50%',
  },
  slider__bottom__controls: {
    color: theme.palette.tertiary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  slide__circle: {
    height: '16px',
    width: '16px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid lightGray',
    display: 'inline-block'
  },
  current__slide__circle: {
    height: '16px',
    width: '16px',
    backgroundColor: 'white',
    borderRadius: '50%',
    border: '2px solid lightGray',
    display: 'inline-block'
  }
}));

const SliderButtons = ({
  imageData,
  currentImage,
  slideHandler,
  handlePrev,
  handleNext,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      className={classes.slider__bottom}
      position="absolute"
      bottom="0"
      gridGap="1rem"
    >
      {imageData.map((_, index) => {
        return (
          <div
            key={index}
            className={`${classes.slider__bottom__controls}${
              index === currentImage
                ? ` ${classes.slider__bottom__controls}--current`
                : ''
            }`}
            onClick={
              index === currentImage ? undefined : () => slideHandler(index)
            }
          >
            {/* {(index + 1).toString().padStart(2, '0')} */}
            <span
            key={index}
            className={`${
              index === currentImage
                ? ` ${classes.current__slide__circle}`
                : ` ${classes.slide__circle}`
            }`}
            />
          </div>
        );
      })}
      {/* <Box>
        <ArrowLeftIcon
          className={classes.slider__bottom__controls}
          fontSize="large"
          onClick={handlePrev}
        />
        <ArrowRightIcon
          className={classes.slider__bottom__controls}
          fontSize="large"
          onClick={handleNext}
        />
      </Box> */}
    </Box>
  );
};

export default SliderButtons;
