import React from 'react';
import AccordionSummary from './AccordionSummary';
import AccordionDetails from './AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const ItemMenuMobile = ({
  exact,
  id,
  to,
  labelLink,
  subMenu = [],
  openClose = () => {},
  state,
  expanded,
  handleChange = () => {},
  classes,
  SubMenuMobile = null,
  target = '_self',
}) => {
  return (
    <Accordion
      expanded={expanded === `panel${id}`}
      onChange={handleChange(`panel${id}`)}
      className={classes.accordion}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}a-content`}
        id={`panel${id}a-header`}
      >
        <NavLink
          exact={exact}
          to={to}
          target={target}
          onClick={() => {
            openClose(!state);
          }}
        >
          {labelLink}
        </NavLink>
      </AccordionSummary>
      <AccordionDetails padding={0}>
        {SubMenuMobile &&
          subMenu &&
          subMenu.map((subItem, idx) => {
            return (
              <SubMenuMobile
                key={idx}
                title={subItem.title}
                target={target}
                linkTo={subItem.linkTo}
                openClose={openClose}
                state={state}
                idx={idx}
                subMenuListItems={subItem.subMenuListItems}
                exact={exact}
              />
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

ItemMenuMobile.propTypes = {
  labelLink: PropTypes.string,
  to: PropTypes.string,
  openClose: PropTypes.func,
  handleChange: PropTypes.func,
  state: PropTypes.bool,
  exact: PropTypes.bool,
  id: PropTypes.number,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  classes: PropTypes.object,
  color: PropTypes.string,
  colorsubmenulist: PropTypes.string,
  subMenuListItems: PropTypes.arrayOf(PropTypes.object),
  SubMenuMobile: PropTypes.func,
  subMenu: PropTypes.arrayOf(PropTypes.object),
};

export default ItemMenuMobile;
