import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiBox from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => {
  return {
    box__overlay: {
      background: (props) =>
        props.imageBox && props.cta
          ? `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.tertiary.main} 75%, ${theme.palette.tertiary.main} 100%)`
          : `linear-gradient(180deg, ${theme.palette.tertiary.main} 0%, ${theme.palette.primary.main} 75%, ${theme.palette.primary.main} 100%)`,
      backgroundColor: (props) =>
        props.imageBox ? theme.palette.secondary.dark : props.backgroundColor,
      mixBlendMode: 'multiply',
      position: 'absolute',
      top: '0',
      height: '100%',
      width: (props) => (props.widthFixed ? props.width : '100%'),
      opacity: '0.75',
    },
    box__image: {
      backgroundImage: (props) => props.imageBox && `url("${props.imageBox}")`,
      backgroundSize: (props) => props.imageBox && 'cover',
      backgroundRepeat: (props) => props.imageBox && 'no-repeat',
      backgroundPosition: (props) => props.imageBox && 'center center',
      height: '100%',
      width: (props) => (props.widthFixed ? props.width : '100%'),
      position: 'relative',
    },
    box__inner__title: {
      fontFamily: 'Druk Wide Medium',
      fontSize: '40pt',
      textTransform: 'uppercase',
      color: (props) => props.colorTitle || theme.palette.tertiary.main,
      fontWeight: (props) => (props.bold ? 'bold' : 'normal'),
      position: 'absolute',
      bottom: 0,
    },
    box__inner__image: {
    },
    box__inner__cta: {
      alignSelf: 'flex-end',
      fontSize: '12pt',
    },
    box: {
      overflow: 'hidden',
      width: (props) => (props.widthFixed ? props.width : '100%'),
      aspectRatio: (props) => props.aspectRatio || '1/1',
      cursor: 'pointer',
    },
    box__inner: {
      position: 'relative',
      zIndex: 1,
      height: '100%',
      width: (props) => (props.widthFixed ? props.width : '100%'),
    },
  };
});

const StaticBox = (props) => {
  const {
    title,
    imageTitle,
    colorTitle,
    colorText,
    backgroundColor = 'black',
    heightFixed = true,
    widthFixed = false,
    imageBox = null,
    variantTitle = 'h5',
    fontweight = true,
    bold = true,
    width = '425px',
    height = '425px',
    cta,
    aspectRatio,
    pageWidth
  } = props;

  const classes = useStyles({
    colorTitle,
    backgroundColor,
    imageBox,
    heightFixed,
    widthFixed,
    colorText,
    fontweight,
    bold,
    width,
    height,
    aspectRatio,
    cta,
  });

  const history = useHistory();
  const handleClick = () => {
    const url = cta.url;
    if (url.includes('http')) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };

  const boxWidth = (pageWidth-(96 + 96))/4;
  let logoWidth = 0;

  switch (true) {
    case imageTitle.includes('infra'):
      logoWidth = boxWidth - 20;
      break;
    case imageTitle.includes('fiom'):
      logoWidth = boxWidth - 20;
      break;
    case imageTitle.includes('finso'):
      logoWidth = (boxWidth - 20)*0.76;
      break;
    case imageTitle.includes('sof'):
      logoWidth = (boxWidth - 20)*0.76;
      break;
    default:
      logoWidth = (boxWidth - 20);
      break;
  }

  return (
    <div
      className={classes.box}
      style={props.style}
      onClick={cta ? handleClick : undefined}
    >
      <div className={classes.box__image}>
        <MuiBox
          display="flex"
          justifyContent={
            (title || imageTitle) && cta ? 'space-between' : 'flex-end'
          }
          flexDirection="column"
          alignItems="flex-start"
          className={classes.box__inner}
        >
          {title && (
            <Typography
              className={classes.box__inner__title}
              variant={variantTitle}
            >
              {title}
            </Typography>
          )}
          {imageTitle && (
            <img
              className={classes.box__inner__image}
              src={imageTitle}
              alt="Company logo"
              width={logoWidth}
            />
          )}
        </MuiBox>
      </div>
    </div>
  );
};

StaticBox.propTypes = {
  title: PropTypes.string,
  colorTitle: PropTypes.string,
  colorText: PropTypes.string,
  backgroundColor: PropTypes.string,
  heightFixed: PropTypes.bool,
  widthFixed: PropTypes.bool,
  textAlign: PropTypes.string,
  imageBox: PropTypes.string,
};

export default StaticBox;
