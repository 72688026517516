import React, { Fragment } from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

function Header({ logo, logoSmall, menuData, menuMobileData }) {
  return (
    <Fragment>
      <HeaderDesktop logoSmall={logoSmall} logo={logo} menuData={menuData} />
      <HeaderMobile logoSmall={logoSmall} menuMobileData={menuMobileData} />
    </Fragment>
  );
}

export default Header;
