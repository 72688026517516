import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const color = {
  primary: {
    main: '#003a78',
    dark: '#001c35',
    light: '#7a99b8',
    morelight: '#CCD1D6',
    plusLight: '#a3b8cc',
  },
  secondary: {
    main: '#757878',
    dark: '#e1e1e1',
    morelight: '#d2d0d0',
    light: '#a9a8a8',
    moredark: '#565558',
  },
  tertiary: {
    main: '#f5f5f5',
    light: '#A3B8CC',
    dark: '#A3ADB5',
    contrastText: '#000',
  },
};

let theme = createMuiTheme({
  breakpoints: {
    values: { xs: 0, sm: 720, md: 950, lg: 1140, xl: 1310 },
  },
  palette: {
    background: {
      default: color.tertiary.main,
    },
    primary: {
      main: color.primary.main,
      dark: color.primary.dark,
      light: color.primary.light,
      morelight: color.primary.morelight,
      plusLight: color.primary.plusLight,
    },
    secondary: {
      main: color.secondary.main,
      light: color.secondary.light,
      dark: color.secondary.dark,
      morelight: color.secondary.morelight,
      moredark: color.secondary.moredark,
    },
    tertiary: {
      main: color.tertiary.main,
      dark: color.tertiary.dark,
      light: color.tertiary.light,
      contrastText: color.tertiary.contrastText,
    },
  },
  typography: {
    fontFamily: ['Druk Wide Medium', 'Trade Gothic LT STD', 'sans-serif'].join(
      ',',
    ),
    h1: {
      fontFamily: 'Druk Wide Medium',
      fontSize: '48pt',
      color: color.primary.main,
      fontWeight: 'normal',
    },
    h2: {
      fontFamily: 'Druk Wide Medium',
      fontSize: '27pt',
      color: color.primary.main,
      fontWeight: 'normal',
    },
    h4: {
      fontFamily: 'Druk Wide Medium',
      fontSize: '16pt',
      color: color.primary.main,
      fontWeight: 'normal',
    },
    h5: {
      fontFamily: 'Druk Wide Medium',
      fontSize: '12pt',
      color: color.primary.main,
      fontWeight: 'normal',
    },
    body1: {
      fontFamily: 'Trade Gothic LT STD',
      fontSize: '13pt',
      color: color.primary.main,
      fontWeight: 'normal',
      textAlign: 'left',
    },
    th: {
      fontFamily: 'Trade Gothic LT STD',
      fontSize: '12pt',
      color: color.primary.main,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    td: {
      fontFamily: 'Trade Gothic LT STD',
      fontSize: '12pt',
      color: color.primary.main,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: color.primary.main,
        color: color.tertiary.main,
        fontSize: '12pt',
        fontFamily: 'Trade Gothic LT STD',
        textTransform: 'unset',
        lineHeight: 1,
        borderRadius: 0,
        '&:hover': {
          backgroundColor: color.primary.dark,
          '@media (hover: none)': {
            backgroundColor: color.primary.main,
          },
        },
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      },
    },
  },
});

export default theme;
